import React from "react";
import "./Timeline.css";
import Footer from "../components/Footer";
import ContactUss from "../components/ContactUss";
import Footers from "./Footers";

const Timeline = () => {
  return (
    <div>
      <div
        className="container-fluid serviceDisplay"
        style={{ marginTop: "15vh" }}
      >
        <div>
          <img
            src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/success+1.jpg"
            alt="sss Banner"
            className="sss-banner-image"
          />
        </div>
      </div>
      <div className="Container">
        <div classname="row">
          <div class="governance-data">
            <h1>TIMELINE - OUR JOURNEY SO FAR</h1>
          </div>

          <div className="container">
            <div className="row">
              <div className="timeline">
                <div className="timeline-item left">
                  <div className="col-md-2">
                    <div className="timeline-image">
                      <img
                        className="rounded-image"
                        alt=""
                        src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/success+1.jpg"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="timeline-content">
                      <h2 className="timeline-date">2009-2010</h2>

                      <p>
                        <ul>
                          <li> SSS established as a proprietorship firm.</li>
                          <li>
                            Industry’s leading in-country corporate OBI training
                            partner.
                          </li>
                          <li>
                            Successfully executed the first project on BIDW to a
                            leading airline company in the Middle East.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="timeline-item right">
                <div className="col-md-2">
                  <div className="timeline-image ">
                    <img
                      alt=""
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/conference+3.jpg"
                    />
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="timeline-content timeline-img2">
                    <h2
                      className="timeline-date"
                      style={{ textAlign: "justify" }}
                    >
                      2011-2013
                    </h2>

                    <p>
                      <ul style={{ textAlign: "justify" }}>
                        <li>
                          {" "}
                          Started providing training on OBI to countries in the
                          Middle East and Oceania.Project implementation on
                          Enterprise wide BI dashboard in the Middle East and
                          Oceania.
                        </li>
                        <li>
                          Successfully executed the first project on BIDW in
                          Northern America.
                        </li>
                        <li>
                          Delivered BIDW training to greater than 1,000
                          candidates across the globe.
                        </li>
                        <li>Incorporated as a Private Limited Company.</li>
                        <li>Became the member of ITAAP.</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="timeline">
                  <div className="timeline-item left">
                    {/* <div className="col-md-2">
                      <div className="timeline-image">
                        <img
                          className="rounded-image"
                          alt=""
                          src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/Front+office+2.jpg"
                        />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="timeline-content">
                        <h2 className="timeline-date">2009-2010</h2>

                        <p>
                          <ul>
                            <li> SSS established as a proprietorship firm.</li>
                            <li>
                              Industry’s leading in-country corporate OBI
                              training partner.
                            </li>
                            <li>
                              Successfully executed the first project on BIDW to
                              a leading airline company in the Middle East.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div> */}
                    <div className="col-md-2">
                      <div className="timeline-image">
                        <img
                          className="rounded-image"
                          alt=""
                          src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/careers.jpg"
                        />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="timeline-content timeline-img2">
                        <h2
                          className="timeline-date"
                          style={{ textAlign: "justify" }}
                        >
                          2014-2017
                        </h2>

                        <p>
                          <ul style={{ textAlign: "justify" }}>
                            <li>
                              {" "}
                              Awarded the “Best Company for 2016” in Business
                              Intelligence category by The CEO Magazine.
                            </li>
                            <li>
                              Successfully executed projects on latest
                              technologies like Looker BI, QlikView, Jaspersoft
                              (Talend).
                            </li>
                            <li>
                              Successfully executed the biggest E-Commerce site
                              to US clients with many advanced features never
                              seen before.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container">
              <div className="row">
                <div className="timeline-item left">
                  <div className="col-md-2">
                    <div className="timeline-image">
                      <img
                        className="rounded-image"
                        alt=""
                        src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/careers.jpg"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="timeline-content timeline-img2">
                      <h2
                        className="timeline-date"
                        style={{ textAlign: "justify" }}
                      >
                        2014-2017
                      </h2>

                      <p>
                        <ul style={{ textAlign: "justify" }}>
                          <li>
                            {" "}
                            Awarded the “Best Company for 2016” in Business
                            Intelligence category by The CEO Magazine.
                          </li>
                          <li>
                            Successfully executed projects on latest
                            technologies like Looker BI, QlikView, Jaspersoft
                            (Talend).
                          </li>
                          <li>
                            Successfully executed the biggest E-Commerce site to
                            US clients with many advanced features never seen
                            before.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <ContactUss /> */}
      <Footers />
    </div>
  );
};

export default Timeline;
