import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const DataGovernance = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/solutions/data_governance/data-governance-min.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Data Governance</h1>
<h4 >In order to make effective decisions and provide exceptional customer experiences,<br/>
organizations require reliable data they can depend on.</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>SSS Data Governance Strategy involves a methodology based on best practices that allows for the
             evaluation of current state capabilities, determination of the desired end state
             for various data governance enablers, and delivery of a comprehensive set of actionable recommendations.​​</p>
       
    </div> </div> 
</div>
<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin row-center">
            <img src="./images/solutions/Data-Accoutability.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">Improve Data<br/> Accountability</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src=" ./images/solutions/Data-Literacy.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Improve Data<br/> Literacy</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/traceability.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Increase Data <br/>Traceability</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/ROI.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Enhanced data<br/> ROI​​</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Data-Debt.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Minimized data<br/> debt​​</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Data-Literacy.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Ethical use and <br/>handling of data​​​</p>
        </div>
    </div>
</div>
      

       <Footer/>
    </div>
  )
}

export default DataGovernance
