


import React, { useState } from 'react';
import './Why.css';
import { Link } from 'react-router-dom';

const Why = () => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const perksData = [
      // Your perks data here, each containing image, title, and description...
      {
          image:'https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/1.png',
      title: 'Business-Minded Tech Experts',
      description: "Combining software expertise with a keen business mindset, our engineers consider the ongoing technology decisions, focusing on cost-effectiveness and the business impact when managing your project.",
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/2.png",
      title: 'Optimized Technology Scaling',
      description: 'Crafting Tailored Software Solutions for Your Unique Business Needs. Leveraging Years of Industry Expertise to Select the Ideal Tech Stack for your Project.',
      },
        {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/3.png",
      title: "High-Grade Software Solutions",
description: "Delivering Efficient and Scalable Digital Products. Our Collaborative the Approach Ensures a Maintainable, Value-Driven, and Fully Functional End Product.",
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/4.png",
      title: "Expert Software Revival",
description: "Rescue Your Software Projects. We Specialize in Identifying Issues, Gathering Data, and Recommending Solutions to Breathe New Life into Troubled Projects."
      },
        {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/5.png",
      title: 'Trusted tech partner',
      description: 'A Culture of Trust and Transparency. We Dont Just Manage, We Collaborate and Cultivate Strong Client Relationships Through Open and Honest Communication',
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/6.png",
    title: "Streamlined Workflow",
description: "We Value Your Time and Prioritize Your Business Goals. Our Efficient Organization, Well-Defined Processes, and Trust-Based Decision Making Ensure Swift Progress."
      },
//         {
//           image:"./images/why choose bg/7.png",
//       title: "Uncompromising Security",
// description: "Security is Our Priority. We Imbue Every Software Solution with Iron-Clad Protection. Your Data's Integrity and Confidentiality, Along with Infrastructure Security, Are Our Unwavering Commitment.",
      //       },
 {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/9.png",
     title: "Tailored Engagement",
description: "Our Team Stays Actively Involved Throughout the Project, Aiming to Understand Your Needs, Address Concerns, and Ensure Clear Communication. We Proactively Work to Define Your Specific Requirements."
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/8.png",
      title: "Global Project Kick-off",
description: "Initiating Projects at Your Headquarters. It's Not Uncommon for Us to Visit Clients Worldwide, Making the Project Kick-off a Valuable Experience.",
      },
//         {
//           image:"./images/why choose bg/9.png",
//      title: "Tailored Engagement",
// description: "Our Team Stays Actively Involved Throughout the Project, Aiming to Understand Your Needs, Address Concerns, and Ensure Clear Communication. We Proactively Work to Define Your Specific Requirements."
//       },
      {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/7.png",
      title: "Uncompromising Security",
description: "Security is Our Priority. We Imbue Every Software Solution with Iron-Clad Protection. Your Data's Integrity and Confidentiality, Along with Infrastructure Security, Are Our Unwavering Commitment.",
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/12.png",

title: "Empowered Development Team",
description: "We Cultivate an Exceptional Work Environment for Engineers, Fostering Empowerment and Trust, Leading to Enhanced Job Satisfaction. Our Team Brings Creativity, Efficiency, and Dedication to Every Project. On Average, Developers Collaborate with Us for 4 Years.",
      },
        {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/13.png",

title: "Direct Collaboration with Our Team",
description: "Engage in Direct and Daily Communication with Our Development Team through Your Project's Slack Channel. Gain Insight into the Details of Our Work, Share Knowledge, and Benefit from Our Team's Expertise."
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/14.png",
      title: "Knowledge Exchange and Growth",
description: "We Foster the Exchange of Knowledge Between SoftwareMill and Our Clients. Collaboratively Discover, Organize, and Share Knowledge, Enabling Mutual Technical Growth and Value in Our Cooperation."
      },
        {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/15.png",
     title: "Dedicated Supportive Team",
description: "Our Developers Provide Daily Support with the Domain Expertise Necessary for Your Project's Success. Through Empathy, Trust, Assertiveness, and Open Communication, We Collaborate to Achieve Remarkable Results Together!"
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/16.png",
      title: "Certified Technical Experts",
description: "Our Team Holds Over 60 Certifications in Cutting-Edge IT Skills, Including Apache Kafka, Apache Cassandra, Reactive Architecture, Data Engineering, Java Programming, Google Cloud, AWS Cloud, Kubernetes, Tensorflow, and More!"
      },
        {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/17.png",
       title: 'Recognized tech experts',
      description: 'Custom software must always be designed for a specific set of business needs...We are IT experts eager to share our know how on various tech events.',
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/18.png",
      title: "Pioneers of Remote Work Strategies",
description: "If You Require Guidance on Shifting to a Remote Business Model, We're Here to Assist. SoftwareMill Has Championed Remote Work Since Inception and Has Developed Proven Strategies for Organizing and Boosting Your Team's Remote Performance."
      },
        {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/19.png",
      title: "Beyond Functional Software",
description: "Embrace Innovation and Collaborate with a Partner who Offers Cross-Domain Expertise. We Share Cutting-Edge Tech Know-How, Excel in Managing Distributed Projects, and Have Perfected Remote Communication Practices."
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/20.png",
      title: "Empowered Self-Organized Teams",
description: "Our self-organized teams take an active role in shaping your company's technical core and prioritize your business success and growth."
      },
        {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/21.png",
      title: "Seamless Communication",
description: "We prioritize transparency, visibility, and seamless information exchange. Direct communication with our developers makes decision-making and discussions faster and easier."
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/23.png",
     title: "Efficient Project Guidance",
description: "Our experienced team of Business Representatives will steer your project in the right direction. Your dedicated Delivery Manager is committed to maximizing the efficiency of the development process."
      },
       {
          image:"https://sssbiimages.s3.ap-south-1.amazonaws.com/why_choose_sss/25.png",
      title: "Comprehensive Quality Assurance",
description: "We specialize in developing use-cases and test scenarios while conducting thorough testing for functionality, security, performance, and automated software on all applications we create for our clients."
    },
  ];

  // Slice the data to show the first 2 rows when not showing all
  const visiblePerks = showAll ? perksData : perksData.slice(0, 8);

  return (
    <div className='why_chouse_us'>
      <section className="content__section section--no-padding section--perks perks why-choose-us">
        <div className="perks__viewport viewport">
          <div className="perks__header section__header">
            {/* <h2 className='text-center' style={{color:"blue",fontSize:"40px"}}>why choose SSS</h2> */}
               <div class="bi-service">
    <h1 className='text-center'>Why Choose SSS</h1>
</div>
          </div>
          <div className="perks__body section__body">
            <div className="perks__slider swiper-slider grid--four-columns ovee-component" data-swiper-perks="" data-bp="(max-width: 1279px)">
              {visiblePerks.map((perk, index) => (
                <div
                  key={index}
                  className={`perks__item perks-item swiper-slider__slide slider__slide swiper-slide`}
                  data-slide-number={index}
                >
                      <img  className='image' src={perk.image} alt={perk.title} />

                  <h4 className="perks-item__title">{perk.title}</h4>
                  <div className="perks-item__desc">
                    <p>{perk.description}</p>
                  </div>
                </div>
              ))}
                      </div>
                       {/* <div className='bt-start-now'>
                      {showAll ? (

              <button type="button" className="btn-start" onClick={toggleShowAll}>
                View Less
              </button>
            ) : (
              <button type="button" className="btn-start" onClick={toggleShowAll}>
                View More
                              </button>


                          )}
                          </div> */}
            {/* <div className='bt-start-now'>
                      {showAll ? (

              <a type="button" className="btn-start" onClick={toggleShowAll}>
                View Less
              </a>
            ) : (
              <a type="button" className="btn-start" onClick={toggleShowAll}>
                View More
                              </a>


                          )}
                          </div> */}





            {/* <div class="bt-start-now">
              {showAll ? (
                <a class="btn-start" type="button"  target="" onClick={toggleShowAll} style={{backgroundColor:"#02CEC4"}}><p>VIEW LESS</p></a>
              ) : (
                <a class="btn-start" type="button"  target="" onClick={toggleShowAll} style={{backgroundColor:"#02CEC4"}}><p>VIEW MORE</p></a>
                  )}
            </div> */}
            <div className="text-center">
              {showAll ? (
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target=""
         onClick={toggleShowAll}
      >
        <span className="fusion-button-text">VIEW LESS</span>
        <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
                </Link>
              ) : (
                   <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
         onClick={toggleShowAll}
      >
        <span className="fusion-button-text">VIEW MORE</span>
        <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
                </Link>
                  )}
    </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Why;
