import React from "react";
import "./Aboutsss.css";
import ContactUss from "../components/ContactUss";
import Footer from "../components/Footer";
import Mission from "../components/Mission";
import OurValues from "../components/OurValues";
import { Link } from "react-router-dom";
import Footers from "./Footers";
const Aboutsss = () => {
  return (
    <div className="about-sss-bg" style={{ backgroundColor: "#f5faff" }}>
      <div className="container-fluid imgDisplay" style={{ marginTop: "15vh" }}>
        <div className="row">
          <div className="col">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/Employees+All.png"
              alt="Nature"
              class="service-header-imgs"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="text-whitedatas">
          {/* <h1 class="text-size-head-whites">About <strong>SSS</strong> Family!</h1> */}
        </div>
      </div>

      <div
        className="container"
        style={{ backgroundColor: "#f5faff", padding: "6px" }}
      >
        <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column">
          <div className="fusion-menu-anchor" id="about"></div>

          {/* <h1 className='text-center' style={{ color: "blue", fontSize: "30px" }}>
            <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif" style={{width:"6%"}} />  ABOUT SSS
        </h1> */}
          <div className="bi-service">
            <h1 className="text-center" style={{ fontSize: "40px" }}>
              <img
                src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif"
                style={{ width: "8%" }}
              />{" "}
              ABOUT SSS
            </h1>
          </div>
          <div className="fusion-separator fusion-no-large-visibility fusion-full-width-sep custom-separator"></div>
          <div className="fusion-text1 fusion-text-3 fusion-text-no-margin custom-text-3">
            <blockquote>
              <p>
                {" "}
                "Srinivasan Software Solutions emerges as a pivotal player in
                the ever-evolving realm of technology and business solutions. As
                one of India's swiftest growing Information Technology and
                consulting firms, we're propelling businesses into a future
                defined by innovation and transformative growth. Our journey
                commenced in 2009, nestled within the sacred embrace of
                Tirupati, a city that reverberates with devotion and tradition.
                Rooted in this spiritual heaven, we've embarked on a mission to
                infuse technological advancements with the sanctity of this
                ancient land."
              </p>
            </blockquote>

            <h2></h2>

            <p>
              Strives to deliver a multi-faceted portfolio of expertise in
              Solutions &amp; Services, Talent, and Technology Platforms to
              enable digital transformation while navigating the need for Global
              Optimization, Security, and Financial Agility.
            </p>
          </div>
          <div className="fusion-separator fusion-full-width-sep custom-separator"></div>
          <div className="fusion-text1 fusion-text-4 custom-text-4">
            <p style={{ color: "#555555" }}>
              All of this is delivered with the client-centric focus of a
              boutique firm, the reach and of a global player, and the
              frictionless experience of a single partner.
            </p>
          </div>
        </div>
      </div>

      <OurValues />

      <div className="container">
        <Mission />
      </div>
      <div className="container">
        <div
          className="row row1 justify-content-center align-items-flex-start fusion-flex-content-wrap"
          style={{
            width: "104%",
            maxWidth: "104%",
            marginLeft: "calc(-4% / 2)",
            marginRight: "calc(-4% / 2)",
          }}
        >
          <div className="bi-service">
            <h1>
              {" "}
              <img
                src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif"
                style={{ width: "8%" }}
              />{" "}
              Offerings​
            </h1>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1501"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/advisory.png"
                  alt="01 ADVISORY"
                />
              </div>
              <div className="fusion-text1 fusion-text-7">
                <p>Advisory</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-center fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1502"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/cybersecurity.png"
                  alt="02 CYBERSECURITY"
                />
              </div>
              <div className="fusion-text1 fusion-text-7">
                <p>Cybersecurity</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-center fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1503"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/data+analytics+offer.png"
                  alt="03 DATA ANALYTICS"
                />
              </div>
              <div className="fusion-text1 fusion-text-8">
                <p>Data & Analytics</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" row row1 justify-content-center align-items-flex-start fusion-flex-content-wrap"
          style={{
            width: "104%",
            maxWidth: "104%",
            marginLeft: "calc(-4% / 2)",
            marginRight: "calc(-4% / 2)",
          }}
        >
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1504"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/enterprise+systems+offerings.png"
                  alt="04 ENTERPIRSE SYSTEMS"
                />
              </div>
              <div className="fusion-text1 fusion-text-9">
                <p>Enterprise Systems</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-center fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1505"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/managed+services+offerings.png"
                  alt="06 MODERN APPLICATIONS"
                />
              </div>
              <div className="fusion-text1 fusion-text-10">
                <p>Managed Services</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-center fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1506"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/modern+applications+offerings.png"
                  alt="07 MODERN APPLICATIONS"
                />
              </div>
              <div className="fusion-text1 fusion-text-11">
                <p>Modern Applications</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className=" row row1 justify-content-center align-items-flex-start fusion-flex-content-wrap"
          style={{
            width: "104%",
            maxWidth: "104%",
            marginLeft: "calc(-4% / 2)",
            marginRight: "calc(-4% / 2)",
          }}
        >
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1507"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/modern+ecosystem+offerings.png"
                  alt="08 MODERN ECOSYSTEM"
                />
              </div>
              <div className="fusion-text1 fusion-text-12">
                <p>Modern Ecosystems</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-center fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1508"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/talent+offerings.png"
                  alt="09 TALENT"
                />
              </div>
              <div className="fusion-text1 fusion-text-13">
                <p>Talent</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-center fusion-content-layout-column text-center">
              <div className="fusion-image-element">
                <img
                  className="img-responsive wp-image-1509"
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/offerings/infrasctructure+offerings.png"
                  alt="05 INFRASCTRUCTURE"
                />
              </div>
              <div className="fusion-text1 fusion-text-14">
                <p>IT Infrastructure</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row1">
          {/* <h1 className='text-center' style={{padding:"20px",fontSize:"40px",color: "#00c6d1"}}> SSS at a Glance</h1> */}
          <div class="bi-service">
            <h1>SSS at a Glance</h1>
          </div>

          <div className="col-md-2 font-styles">
            <p className="glance-top-style">
              <span>14+</span>
            </p>

            <div
              className="fusion-text1 fusion-text-17 fusion-no-small-visibility fusion-no-medium-visibility fusion-text-no-margin"
              style={{ fontSize: "23px" }}
            >
              <p>
                Years’
                <br />
                Experience
              </p>
            </div>
          </div>
          <div className="col-md-2 font-styles">
            <p className="glance-top-style">
              <span>100+</span>
            </p>

            <div
              className="fusion-text1 fusion-text-17 fusion-no-small-visibility fusion-no-medium-visibility fusion-text-no-margin"
              style={{ fontSize: "23px" }}
            >
              <p>
                Global
                <br />
                Employees
              </p>
            </div>
          </div>
          <div className="col-md-2 font-styles">
            <p className="glance-top-style">
              <span>1K+</span>
            </p>

            <div
              className="fusion-text1 fusion-text-17 fusion-no-small-visibility fusion-no-medium-visibility fusion-text-no-margin"
              style={{ fontSize: "23px" }}
            >
              <p>
                Successful
                <br />
                Projects
              </p>
            </div>
          </div>
          <div className="col-md-2 font-styles">
            <p className="glance-top-style">
              <span>50+</span>
            </p>

            <div
              className="fusion-text1 fusion-text-17 fusion-no-small-visibility fusion-no-medium-visibility fusion-text-no-margin"
              style={{ fontSize: "23px" }}
            >
              <p>
                Technical
                <br />
                Certifications
              </p>
            </div>
          </div>
          <div className="col-md-2 font-styles">
            <p className="glance-top-style">
              <span>2+</span>
            </p>

            <div
              className="fusion-text1 fusion-text-17 fusion-no-small-visibility fusion-no-medium-visibility fusion-text-no-margin"
              style={{ fontSize: "23px" }}
            >
              <p>
                Offices
                <br />
                Worldwide
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <Link
          className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
          target="_self"
          to="/"
        >
          <span className="fusion-button-text">Back To Home</span>
          <i
            className="fa-long-arrow-alt-right fas button-icon-right"
            aria-hidden="true"
          ></i>
        </Link>
      </div>

      <br />
      {/* <ContactUss /> */}
      <Footers />
    </div>
  );
};

export default Aboutsss;
