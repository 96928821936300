import { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import "./Carousel.css";

function CloudDataCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexFlow: "nowrap",
        justifyContent: "center",
      }}
    >
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: "center",
            background: "black",
            border: "none",
            borderRadius: "50%",
            color: "white",
            cursor: "pointer",
            fontSize: "30px",
            height: 40,
            lineHeight: 1,
            textAlign: "center",
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: "center",
            background: "black",
            border: "none",
            borderRadius: "50%",
            color: "white",
            cursor: "pointer",
            fontSize: "20px",
            height: 40,
            lineHeight: 1,
            textAlign: "center",
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 780,
          },
        ]}
        speed={400}
        easing="linear"
        className="carousel-container"
      >
        <div style={{ width: 310, height: 650 }}>
          <div class="card bi-card-size">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/data-collection.png"
              class="rpa-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-body-color-black">
                App Design, Development <br />
                and Ideation
              </h5>
              <ul class="card-text ">
                <li>Concept development and ideation.​</li>
                <li>User experience (UX) and user interface (UI) design.​</li>
                <li>Wireframing and prototyping.​</li>
                <li>External Data Sources​</li>
                <li>Native app development (iOS, Android).</li>
                <li>
                  Cross-platform app development (using frameworks like React
                  Native, Flutter)
                </li>
                {/* <li> Progressive web app (PWA) development.</li> */}
              </ul>
            </div>
          </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
          <div class="card bi-card-size">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/data-transformation.png"
              class="rpa-img-top"
              alt="..."
            />
            <div class="card-body ">
              <h5 class="card-title text-body-color-black">
                Web App and Custom Software Development
              </h5>
              <ul class="card-text">
                <li>
                  Building web applications using front-end and back-end
                  technologies.
                </li>
                <li>Single-page applications (SPAs) development.​</li>
                <li>E-commerce website development.</li>
                <li>Content management system (CMS) development.​</li>

                <li>
                  Developing custom software solutions tailored to the client's
                  specific needs.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
          <div class="card bi-card-size">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/data-governance.png"
              class="rpa-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-body-color-black">
                App and Cloud Services Integration
              </h5>
              <ul class="card-text">
                <li>Integration with third-party APIs and services.​​</li>
                <li>Data synchronization between different systems.​</li>
                <li>Legacy system modernization.​</li>
                <li>
                  Integration with cloud platforms (e.g., AWS, Azure, Google
                  Cloud).
                </li>
                <li>Serverless architecture development.​</li>
                <li>Data Privacy Regulations Compliance</li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
          <div class="card bi-card-size">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/Advance-Analytics-Services.png"
              class="rpa-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-body-color-black">
                Quality Assurance,Testing maintenance and Support
              </h5>
              <ul class="card-text">
                <li>
                  Comprehensive testing services, including manual and automated
                  testing.
                </li>
                <li>Test strategy and planning.​</li>
                <li>Security testing and vulnerability assessment.</li>
                <li>Ongoing app maintenance and updates.​</li>
                <li>Bug fixes and performance enhancements.</li>
                <li>Technical support and troubleshooting.</li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
          <div class="card bi-card-size">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/Data-Warehouse.png"
              class="rpa-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-body-color-black">
                Consulting, Strategy​ and UI/UX Enhancement
              </h5>
              <ul class="card-text">
                <li>Providing technical consulting and guidance.​​</li>
                <li>Developing app roadmaps and strategies.​</li>
                <li>Technology stack selection and architecture planning.​</li>
                <li>
                  Improving the user interface and user experience of existing
                  apps.
                </li>
                <li>
                  Conducting usability studies and user feedback analysis.
                </li>
                <li>Technology Landscape Review</li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
          <div class="card bi-card-size">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/Data-Visualization-Data-Storytelling-2.png"
              class="rpa-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-body-color-black">
                App Marketing Custom API Development
              </h5>
              <ul class="card-text">
                <li>App store optimization (ASO)..​</li>
                <li>
                  App marketing strategies, including social media promotion and
                  advertising.
                </li>
                <li>Creating custom APIs to support app functionality.</li>
                <li>API documentation and management.</li>
                <li>Implementing security measures to protect user data.​</li>
              </ul>
            </div>
          </div>
        </div>
      </ReactSimplyCarousel>
    </div>
  );
}

export default CloudDataCarousel;
