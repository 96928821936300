import React from "react";
import "./ServicesStyle.css";
import Footer from "../components/Footer";
import TechnologyList from "../components/TechnologyList";
import CloudMigrationCarousel from "./CloudMigrationCarousel";
import CloudMigrationCarousel1 from "./CloudMigrationCarousel1";
import { Link } from "react-router-dom";
import Footers from "../components/Footers";
const CloudData = () => {
  return (
    <div>
      <div
        className="container-fluid serviceDisplay"
        style={{ marginTop: "15vh" }}
      >
        <div className="row  ">
          <div className="col">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Service+page+sliders/Website+Sliders+Service+Page-05.jpg"
              alt="Nature"
              class="service-header-imgs-service"
            />
          </div>
        </div>
      </div>

      <div class="container-fluid ">
        <div class="text-whitedata whitedata">
          <h1 class="text-size-head-white serviceDisplay-tag">
            Cloud Migration{" "}
          </h1>
          <h4 class="text-size-head-white-h4 serviceDisplay-tags">
            Enable your enterprise-wide digital transformation with cloud
            implementation
          </h4>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="insight-matters">
            {" "}
            <h1>Insights that matter</h1>
            <p class="governance-data-size">
              Cloud Migration is a process whereby an organization’s digital
              assets, resources and services or applications are deployed in the
              cloud, where the migrated assets cannot be accessed beyond the
              cloud’s firewall.
            </p>
            <p class="governance-data-size">
              {" "}
              Our expert engineers and dedicated teams helps your migration and
              modernization initiatives see a tangible business impact while
              moving to the cloud.
            </p>
            {/* <div class="bt-start-now">  <a class="btn-start" type="button" value="START NOW" placeholder="START NOW" href="/contact" target=""><p>START NOW</p></a>
            </div> */}
            <div className="text-center">
              <Link
                className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
                target="_self"
                to="/contact"
              >
                <span className="fusion-button-text">START NOW</span>
                {/* <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i> */}
              </Link>
            </div>
          </div>{" "}
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="governance-data">
            <h1>
              The Growing Demand for Data Analytics over the Cloud Migration
            </h1>

            <p class="governance-data-size">
              The growing demand for data analytics over cloud migration
              represents a significant trend in the technology and business
              landscape. This trend is driven by various factors that highlight
              the advantages of combining data analytics and cloud computing.
              SSS helps clients leverage the cloud migration for business
              acceleration, scalability, application modernization, and
              achieving goals with skilled professionals and diverse cloud
              migration technologies.
            </p>
            <br />
            <h6 class="challenges-size">
              Cloud migration technology in enabling agile and flexible ways of
              working, as well as its significance in digital transformation and
              addressing data challenges:
            </h6>
          </div>

          <div class="col-md-6">
            <div class="challenges">
              {/* <h6 class="challenges-size">Cloud migration technology in enabling agile and flexible ways of working,
             as well as its significance in digital transformation and addressing data challenges:</h6> */}

              <ul>
                <p class="challenges-margin">
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Agile and Flexible Operations
                </p>
                <p>
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Digital Transformation
                </p>
                <p>
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Legacy System Modernization
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Global Accessibility and Collaboration
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Security and Compliance
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Data Challenges
                </p>
              </ul>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="interactive">
              {/* <h6 class="challenges-size">Amid the Covid-19 crisis, businesses faced unprecedented challenges that prompted a rapid acceleration of digital transformation efforts. Cloud migration technology played a vital
             role in enabling remote work and offering significant benefits during this time.:</h6> */}
              <br />
              <br />
              <ul>
                <p class="">
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Flexibility for Innovation
                </p>
                <p class="">
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Remote Work Enablement
                </p>
                <p class="">
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Collaboration and Communication
                </p>
                <p class="">
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Cost Efficiency
                </p>
                <p class="">
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Global Accessibility
                </p>
                <p class="">
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Adaptability to Uncertainty
                </p>

                <br />

                <br />
              </ul>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row service-row1">
              <div class="bigdata">
                <h1>Cloud Migration Practice – Services Offerings​</h1>
              </div>
              <CloudMigrationCarousel />
            </div>
          </div>

          {/* <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1>Cloud Migration Practice – Services Offerings​</h1>
</div>

<div class="slide-show">
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

    <div class="carousel-item active">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size"  >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Advisory-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Cloud Migration ​Advisory Services​</h5>
      <ul class="card-text " >
        <li>Assessment and Planning​</li>
        <li>Architecture and Design​</li>
        <li>Security and Compliance</li>
        <li>Migration Strategy​</li>
        <li>Performance Optimization</li>
        <li>Post-Migration Support</li>
        <li>Immediate Issue Resolution</li>
       </ul> </div>
  </div>
  <div class="card bi-card-size" >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-BI.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black">Cloud Migration for BI, Data Science solutions​</h5>
      <ul class="card-text" >
      <li> Reduced Maintenance Burden</li>
      <li>Rapid Prototyping and Experimentation​</li>
      <li>Automation and Orchestration</li>
      <li> Advanced Analytics Tools​</li>
      <li>Seamless workload migration Cloud governance and security</li>
      <li>Scalability and Performance</li>


       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Cloud Migration ​Managed Services​</h5>
      <ul class="card-text" >
      <li>Aids DevOps practices​​</li>
      <li>Manages AWS, Azure, or GCP cloud migration platforms​</li>
      <li>Infrastructure Managemen​</li>
      <li>Utilizes analytics tools</li>
      <li>Security and Compliance, flexible infrastructure for data analytics​</li>
      <li>Accelerates development cycles, time-to-market for new features</li>
       </ul>
    </div>
  </div>
 </div> </div>

 <div class="carousel-item">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-BI.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Cloud Migration for BI, Data Science solutions​</h5>
      <ul class="card-text" >
      <li> Reduced Maintenance Burden</li>
      <li>Rapid Prototyping and Experimentation​</li>
      <li>Automation and Orchestration</li>
      <li> Advanced Analytics Tools​</li>
      <li>Seamless workload migration Cloud governance and security</li>
      <li>Scalability and Performance</li>
      <li>Accessibility and Collaboration</li>

       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Cloud Migration ​Managed Services​</h5>
      <ul class="card-text" >
      <li>Aids DevOps practices​​</li>
      <li>Manages AWS, Azure, or GCP cloud migration platforms​</li>
      <li>Infrastructure Managemen​</li>
      <li>Utilizes analytics tools</li>
      <li>Security and Compliance, flexible infrastructure for data analytics​</li>
      <li>Accelerates development cycles, time-to-market for new features</li>
       </ul>
    </div>
  </div>

  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud_migration/Cloud-Migration.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Cloud ​Migration <br/>Services</h5>
      <ul class="card-text">
      <li>Deep dive into main frame apps, batches, datasets​</li>
      <li>Migrate to Big Data, decommission mainframe</li>
      <li>Archive legacy data on Hadoop, Elasticsearch</li>
      <li>Re-platform ETL processes with Spark</li>
      <li>Offload EDW to Big Data, Cloud migration​</li>
      <li>Automation and Orchestration</li>
       </ul>
    </div>
  </div>
 </div></div>
</div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div></div>
</div></div>  */}

          <div class="container">
            <div class="row">
              <div class="mg-management">
                <h1>
                  Cloud Data Practice – Programs | Frameworks | Accelerators
                </h1>
              </div>
              <CloudMigrationCarousel1 />
            </div>
          </div>

          {/* <div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Cloud Data Practice – Programs | Frameworks | Accelerators</h1>
</div>


<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row" >
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-migration-card-body-colors" >
              <h5 class="card-title">Cloud Migration Roadmap Strategy Program​ </h5>
              <ul class="card-text">
              <li>Assessment and Planning​</li>
              <li>Cloud Platform Selection</li>
              <li>Architecture and Design​</li>
              <li>Data Migration Strategy</li>
              <li> Application Refactoring and Optimization​</li>
              <li>Security and Compliance Planning</li>
              <li> Post-Migration Support and Optimization</li>
              <li>Testing and Validation</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-migration-card-body-colors" >
            <h5 class="card-title">Cloud Migration Platform Implementation Program​</h5>
              <ul class="card-text">
              <li>Reduce risk by migrating applications</li>
              <li>Utilize IaaS, PaaS, DWaaS, SaaS</li>
              <li>Fully flexible and scaleable solutions</li>
              <li>Configure, deploy, and migrate data​</li>
              <li>Ensure data quality, validation​</li>
              <li>Application Deployment and Configuration​</li>


                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-migration-card-body-colors"  >

              <h5 class="card-title">Cloud Migration Hypercare Program​ </h5>
              <ul class="card-text">
              <li>Immediate Issue Resolution​</li>
              <li>Monitoring and Performance Tuning​</li>
              <li>Communication and Stakeholder Management​</li>
              <li>Post-Migration Optimization,</li>
              <li>Documentation and Knowledge Transfer​</li>
              <li>On-demand experienced resource availability​</li>
              <li>Continuous Monitoring </li>

                </ul>
               </div>
          </div>
        </div>
      </div>
    </div>



    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-migration-card-body-colors">
            <h5 class="card-title">Cloud Migration Platform Implementation Program​</h5>
              <ul class="card-text">
              <li>Reduce risk by migrating applications</li>
              <li>Utilize IaaS, PaaS, DWaaS, SaaS</li>
              <li>Fully flexible and scaleable solutions</li>
              <li>Configure, deploy, and migrate data​</li>
              <li>Ensure data quality, validation​</li>
              <li>Application Deployment and Configuration​</li>


                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-migration-card-body-colors">

              <h5 class="card-title">Cloud Migration Hypercare Program​ </h5>
              <ul class="card-text">
              <li>Immediate Issue Resolution​</li>
              <li>Monitoring and Performance Tuning​</li>
              <li>Communication and Stakeholder Management​</li>
              <li>Post-Migration Optimization,</li>
              <li>Documentation and Knowledge Transfer​</li>
              <li>On-demand experienced resource availability​</li>
              <li>Continuous Monitoring </li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-migration-card-body-colors">
            <h5 class="card-title">SSS Process Delivers Market-leading Cloud Migration Solutions​</h5>
              <ul class="card-text">
             <li>Assessment questionnaire, adoption readiness report</li>
             <li>Migration options, Migration plan​</li>
             <li>Agile process, migration  teams</li>
             <li>Intial migration, provisioning</li>
             <li>Product migration, Optimization​</li>
             <li>Issue resolutions, training ops teams </li>



              </ul>
           </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div></div> */}

          <div class="container">
            <div class="row" style={{ marginTop: "0px", marginBottom: "30px" }}>
              <div class="bi-service">
                <h1>Our Technology Expertises</h1>
              </div>

              <TechnologyList />
            </div>
          </div>
        </div>
      </div>

      <Footers />
    </div>
  );
};

export default CloudData;
