import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBriefcase,
  faBuilding,
  faEnvelope,
  faPhone,
  faGlobe,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
`;

const Row = styled.div`
  // display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

const FormGroup = styled.div`
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  ${"" /* font-family:"Poppins"; */}
  font-family:"Roboto";
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 30px;
  font-size: 16px;
  background: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: white;
    outline: none;
  }
`;
const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;
const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 30px;
  font-size: 16px;
  background: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: white;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 30px;
  resize: vertical;
  font-size: 16px;
  background: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: white;
    outline: none;
  }
`;

const ContactForm = () => {
  const [formData, setFormData] = useState({
    Name: "",
    // Job: '',
    // Company: '',
    Email: "",
    PhoneNumber: "",
    State: "",
    Country: "",
    Message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     const requiredFields = ['Name', 'Email'];
  //     const isEmptyField = requiredFields.some((field) => !formData[field]);

  //     if (isEmptyField) {
  //       window.alert('Please fill out all required fields before submitting.');
  //       return;
  //     }

  //     // fetch('http://localhost:3000/candidate_contact_details', {
  //     //   method: 'POST',
  //     //   headers: {
  //     //     'Content-Type': 'application/json',
  //     //   },
  //     //   body: JSON.stringify(formData),
  //     // })
  //     //   .then((response) => response.json())
  //     //   .then((data) => {
  //     //     if (data.message === 'Form data inserted successfully') {
  //     //       window.alert('Form submitted successfully');
  //     //       setFormData({
  //     //         Name: '',
  //     //         Job: '',
  //     //         Company: '',
  //     //         Email: '',
  //     //         PhoneNumber: '',
  //     //         Country: '',
  //     //         AdditionalInformation: '',
  //     //       });
  //     //     } else {
  //     //       if (data.error) {
  //     //         window.alert(`Form submission failed: ${data.error}`);
  //     //       } else {
  //     //         window.alert('Form submission failed');
  //     //       }
  //     //     }
  //     //   })
  //     //   .catch((error) => {
  //     //     console.error('Error:', error);
  //     //     window.alert('An error occurred during form submission');
  //     //   });
  //    // Save to localStorage
  //    localStorage.setItem('contactFormData', JSON.stringify(formData));
  //    window.alert('Form data saved locally.');

  //    // Send email using EmailJS
  //    emailjs
  //      .send(

  //          "service_t5lmzv3", // Replace with your EmailJS Service ID
  //          "template_f6na2pg", // Replace with your EmailJS Template ID
  //          formData,
  //          "xSmhzUtIRPQMqK8LD" // Replace with your EmailJS Public Key
  //        // Replace with your EmailJS user ID
  //      )
  //      .then(
  //        (result) => {
  //          console.log('Email sent successfully:', result.text);
  //          window.alert('Email sent successfully.');
  //          setFormData({
  //            Name: '',
  //           //  Job: '',
  //           //  Company: '',
  //            Email: '',
  //            PhoneNumber: '',
  //            State:'',
  //            Country: '',
  //            Message: '',
  //          });
  //        },
  //        (error) => {
  //          console.error('Error sending email:', error);
  //          window.alert('Failed to send email.');
  //        }
  //      );
  //  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = ["Name", "Email"];
    const isEmptyField = requiredFields.some((field) => !formData[field]);

    if (isEmptyField) {
      window.alert("Please fill out all required fields before submitting.");
      return;
    }

    // Save to localStorage
    localStorage.setItem("contactFormData", JSON.stringify(formData));
    window.alert("Form data saved successfully.");

    // Send email using EmailJS
    emailjs
      .send(
        "service_54x5jpf", // Replace with your EmailJS Service ID
        "template_9a7fhit", // Replace with your EmailJS Template ID
        formData,
        "xSmhzUtIRPQMqK8LD" // Replace with your EmailJS Public Key
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          window.alert("Email sent successfully.");

          // Create a JSON file from the form data
          // const jsonData = JSON.stringify(formData, null, 2); // Convert formData to JSON with pretty formatting
          // const blob = new Blob([jsonData], { type: "application/json" });
          // const link = document.createElement("a");
          // link.href = URL.createObjectURL(blob);
          // link.download = "contact-details.json"; // File name for the JSON file
          // link.click();

          // Reset form data
          setFormData({
            Name: "",
            Email: "",
            PhoneNumber: "",
            State: "",
            Country: "",
            Message: "",
          });
        },
        (error) => {
          console.error("Error sending email:", error);
          window.alert("sent successfully.");
        }
      );
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faUser} /> Name
          </Label>
          <InputWrapper>
            <Input
              type="text"
              style={{ color: "white" }}
              name="Name"
              value={formData.Name}
              onChange={handleInputChange}
            />
          </InputWrapper>
        </FormGroup>
        {/* <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faBriefcase} /> Job
          </Label>
          <InputWrapper>
            <Input type="text" style={{color:"white"}} name="Job" value={formData.Job} onChange={handleInputChange} />
          </InputWrapper>
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faBuilding} /> Company
          </Label>
          <InputWrapper>
            <Input type="text"  style={{color:"white"}} name="Company" value={formData.Company} onChange={handleInputChange} />
          </InputWrapper>
        </FormGroup> */}
      </Row>
      <Row>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faEnvelope} /> Email
          </Label>
          <Input
            type="email"
            style={{ color: "white" }}
            name="Email"
            value={formData.Email}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faPhone} /> Phone
          </Label>
          <Input
            type="tel"
            style={{ color: "white" }}
            name="PhoneNumber"
            value={formData.PhoneNumber}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faGlobe} /> State
          </Label>
          <Input
            type="text"
            name="State"
            style={{ color: "white" }}
            value={formData.State}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faGlobe} /> Country
          </Label>
          <Input
            type="text"
            name="Country"
            style={{ color: "white" }}
            value={formData.Country}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faComment} /> Message
          </Label>
          <Input
            type="text"
            name="Message"
            style={{ color: "white" }}
            value={formData.Message}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Row>

      <div className="text-center">
        {/* <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/contact"
      >
        <span className="fusion-button-text">SUBMIT</span>
        <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
      </Link> */}
        <Button type="submit">Submit</Button>
      </div>
    </FormContainer>
  );
};

export default ContactForm;
