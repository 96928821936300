import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function DataEngineeringCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div style={{flexDirection:"row",flexFlow:"none"}}>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            flexFlow:'none',
            flexDirection:'row',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            flexDirection:'row',
            border: 'none',
            flexFlow:'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 780,
          },
        ]}
        speed={400}
        easing="linear" style={{flexDirection:"row",flexFlow:"none"}}
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div style={{ width: 310, height: 650, }}>
        <div class="card bi-card-size"  >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/data-collection.png" class="rpa-img-top" alt="..." style={{width:"100px",height:"80px"}} />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Data Collection <br/>and Ingestion​</h5>
      <ul class="card-text " >
        <li>Event Tracking and Tagging​</li>
        <li>User Behavior Analysis​</li>
        <li>Log Data Ingestion​</li>
                              <li>External Data Sources​</li>
                              <li>Data Enrichment​</li>
                              <li>Clickstream Data​</li>
                             <li> API Rate Limiting and Quotas</li>
       <li> Data Validation and Schema Evolution</li>
       </ul> </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size" >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/data-transformation.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black">Data Transformation<br/> and ETL ​</h5>

      <ul class="card-text" >
      <li>Provide solutions using appropriate Data Profiling for Data Engineering services</li>
      <li>Data Cleansing​</li>
      <li>Data Normalization and Standardization</li>
      <li>Data Enrichment and Augmentation​</li>
      <li>Data Aggregation and Summarization</li>

       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/data-governance.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Governance <br/>and Security</h5>
      <ul class="card-text" >
      <li>Access Control and Authorization​​</li>
      <li>Data Encryption​</li>
      <li>Masking and Anonymization​</li>
      <li>Data Retention and Purging</li>
      <li>Offers scalable, flexible infrastructure for data engineering​</li>
      <li>Data Privacy Regulations Compliance</li>
       </ul>
    </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Data Analytics and<br/> Machine Learning ​</h5>
      <ul class="card-text">
      <li>Data Sampling and Splitting</li>
      <li>Data Preprocessing for ML​</li>
      <li>Data Versioning and Lineage for ML</li>
      <li>Data Labeling and Annotation​</li>
      <li>Data Bias and Fairness Assessment</li>
    <li>Data Lineage</li>
    <li>Data Exploration and Descriptive Analytics</li>
       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/Data-Warehouse.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Data Warehousing<br/> ​Managed Services​</h5>
      <ul class="card-text">
      <li>Data Partitioning and Clustering​​</li>
      <li>Data Compression and Storage Optimization​</li>
      <li>ETL into Data Warehouse​</li>
      <li>Incremental Loading</li>
      <li>Data Compression and Storage Optimization​</li>
      <li>Regularly monitoring and optimizing query performance.</li>
       </ul>
    </div>
  </div>

        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-engineering/Data-Visualization-Data-Storytelling-2.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Visualization<br/> Services</h5>
      <ul class="card-text">
      <li>Creating interactive dashboards that allow users to explore data, change parameters.​</li>
      <li>Developing custom visualizations that address specific business needs</li>
      <li>Integrating real-time data streams into visualizations</li>

      <li>Trend Analysis and Time Series Visualizations​</li>
       </ul>
    </div>
  </div>
        </div>

      </ReactSimplyCarousel>
    </div>
  );
}

export default DataEngineeringCarousel;
