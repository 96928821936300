import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPlayer from "react-player";
import { Form, Button } from "react-bootstrap";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import "./ContactUs.css";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Footers from "./Footers";

// import Footer from './components/Footer'
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    job: "",
    companyName: "",
    email: "",
    phone: "",
    country: "",
    additionalInfo: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here, e.g., send data to server
    console.log(formData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="img-fluid">
      <img
        src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/Contact+Image+3.jpg"
        style={{ width: "100%", height: "20%" }}
      />

      {/* <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-6 text-center">
          <p className="">Start your data journey with SSS</p>
        </div>
      </div>
    </div> */}
      <div></div>

      <div className="container-fluid">
        <div className="content-overlay">
          <div className="container-fluid">
            <h1 className="text-center1" style={{ fontSize: "50px" }}>
              Start your data journey with SSS
            </h1>
            <p></p>
            <p className="text-center1">
              {" "}
              If you want to see our solutions in action or just have a few
              questions,
              <br />
              get in touch with us.
            </p>
            <p></p>
            <p className="text-center1">You’ll hear back from us.</p>

            <p
              className="text-center start-journey"
              style={{ fontFamily: "Roboto" }}
            >
              Start your data journey with us today!
            </p>

            <div></div>
          </div>
        </div>
      </div>
      <ContactUs />

      <section
        className="addressSection sectionPadding"
        style={{ padding: "5%" }}
      >
        <div
          className="auto-container"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <div className="row">
            <div className="col-sm-12 headingUnderline">
              <div className="text-center h1">
                <h2 className="tech-h2">Our Presence Around the World</h2>
              </div>
              <p
                className="h2SubPara"
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                  paddingTop: "3%",
                }}
              >
                Want a quick response? Our amazing people with a presence across
                the globe would love to answer your queries efficiently.
              </p>
            </div>
          </div>
          <div
            className="row itemCenter sss-address-row"
            style={{ paddingTop: "3%" }}
          >
            <div className="col-sm-6">
              <div className="sss-address">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <br />
                      <br />

                      <div className="col-md-6 contact-box">
                        <img
                          src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/india.png"
                          alt="india"
                        />
                        <p></p>
                        {/* <h3>Srinivasan Software Solutions Pvt. Ltd.</h3> */}
                        <h3>SSS Pvt. Ltd.</h3>
                        <p
                          className="compLocation"
                          style={{ fontSize: "20px" }}
                        >
                          K T Road,
                          <br />
                          Near HDFC bank, <br />
                          Tirupathi,
                          <br />
                          Andhra Pradesh, 517501
                          <br />
                          Phone: +91 720 721 3437
                        </p>
                      </div>
                      <div className="col-md-6 contact-box">
                        <img
                          src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/canada+flag.png"
                          alt="canada"
                          style={{ width: "20%" }}
                        />

                        <h3>SSS Pvt. Ltd.</h3>
                        <p
                          className="compLocation"
                          style={{ fontSize: "20px" }}
                        >
                          238 Fleetwood Dr Oshawa,
                          <br /> L1K3E7,
                          <br /> Ontario,
                          <br /> Canada
                        </p>
                        <p>
                          <br />
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="sss-img-box">
                <iframe
                  title="INDIA Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.208729886273!2d79.41711941491941!3d13.64506334041656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d4afbfa1164a9%3A0x82691ad9f6f722f8!2sSrinivasan+Software+Solutions+Pvt.+Ltd.%2C!5e0!3m2!1sen!2sin!4v1517375339034"
                  width="100%"
                  height="350"
                  allowFullScreen
                  loading="lazy"
                  className="border-0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="addressSection sectionPadding"
        style={{ color: "white", fontFamily: "Roboto" }}
      >
        <div className="container">
          <div className="row" style={{ fontSize: "22px" }}>
            <div className="col-md-4">
              <h3>CONTACT ADDRESS:</h3>
              <p>
                Phone : 0877-2233733,
                <br />
                Phone :+91 720 721 3437
              </p>
              <p>
                <Link to="mailto:info@sssbitech.com ">info@sssbitech.com </Link>
              </p>
            </div>
            <div className="col-md-6">
              <h3>CONTACT HR:</h3>
              <p>Phone: +91 720 777 2576,</p>
              <p>
                <Link to="mailto:hr@sssbitech.com ">hr@sssbitech.com</Link>
              </p>
            </div>
            {/* <div className='col-md-3'></div>
             <div className='col-md-3'></div> */}
          </div>
        </div>
      </section>

      <div className="text-center" style={{ marginBottom: "10px" }}>
        <Link
          className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
          target="_self"
          to="/"
        >
          <span className="fusion-button-text">Back To Home</span>
          <i
            className="fa-long-arrow-alt-right fas button-icon-right"
            aria-hidden="true"
          ></i>
        </Link>
      </div>

      <Footers />
    </div>
  );
};

export default Contact;
