import React from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import { Link, useLocation } from "react-router-dom";

const BackgroundVideo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  z-index: -1;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ContactUs = () => {
  const location = useLocation();

  return (
    <div>
      <div className="text-white">
        <h2 className="text-center1" style={{ fontSize: "30px" }}>
          Contact Us
        </h2>
        <p className="text-center contact-details">
          <span className="contact-location text-center1">
            <strong>India</strong> – Tirupati, Andhra Pradesh
          </span>
        </p>
      </div>

      <ContactUsContainer>
        <ContactForm />
      </ContactUsContainer>

      

      <BackgroundVideo>
        <video autoPlay muted loop>
          <source
            src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/bg-Footer.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </BackgroundVideo>
    </div>
  );
};

export default ContactUs;
