import React from "react";
// import {BrowserRouter,Router} from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import SearchBox from "./components/SearchBox";
import Contact from "./components/Contact";
import ContactUs from "./components/ContactUs";
import OurValues from "./components/OurValues";
import Timeline from "./components/Timeline";
import BannerContainer from "./components/BannerContainer";
import LeadershipTeam from "./components/LeadershipTeam";
import Home from "./components/Home";
import Footer from "./components/Footer";
// import BiVisualization from "./services/BiVisualization";
import CloudData from "./services/CloudData";
// import DataPlatforms from './services/DataPlatforms';
import DataScience from "./services/DataScience";
import ManagedServices from "./services/ManagedServices";
// import ManagementGov from './services/ManagementGov';
// import RoboticAutomation from './services/RoboticAutomation';
// import TestingServices from './services/TestingServices';
import Careers from "./components/Careers";
// import Gallery from './components/Gallery';
import Aboutsss from "./components/Aboutsss";
import SuccessStory from "./components/SuccessStory";
// import BigData from "./solutions/BigData";
import AiRoadmap from "./solutions/AiRoadmap";
import DataGovernance from "./solutions/DataGovernance";
import DataStrategy from "./solutions/DataStrategy";
import DataScienceService from "./solutions/DataScienceService";
import BigDataManged from "./solutions/BigDataManaged";
import BiPlatform from "./solutions/BiPlatform";
import Cloudera from "./solutions/Cloudera";
import ClouderaManaged from "./solutions/ClouderaManaged";
import ContractAbstraction from "./solutions/ContractAbstraction";
import ControlTowers from "./solutions/ControlTowers";
import DataAnalyticsManaged from "./solutions/DataAnalyticsManaged";
import IndustrialIot from "./solutions/IndustrialIot";
import Manufacturing from "./solutions/Manufacturing";
import MaterialInflation from "./solutions/MaterialInflation";
import PowerBiMigration from "./solutions/PowerBiMigration";
import SnowFlake from "./solutions/SnowFlake";
import SnowflakeManaged from "./solutions/SnowflakeManaged";
import SupplyChain from "./solutions/SupplyChain";
import TableauMigration from "./solutions/TableauMigration";
import DataEngineering from "./services/DataEngineering";
import DevopsServices from "./services/DevopsServices";
import ProcessDevelopment from "./services/ProcessDevelopment";
import CloudMigration from "./services/CloudMigration";
import Gallery from "./components/Gallery";
import Clients from "./components/Clients";
import AppDevelopment from "./services/AppDevelopment";
import CareerSection from "./components/CareerSection";
import Blog from "./components/Blog";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AboutHome from "./components/AboutHome";
import DataAnalytics from "./services/DataAnalytics";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import VideoGallery from "./components/VideoGallery";
import Flags from "./components/Flags";
import Why from "./components/Why";
import Whyy from "./components/Whyy";
import TestimonialSlider from "./components/Sliders";
import Sliders from "./components/Sliders";

// import Whoo from './components/Whoo';

function App() {
  return (
    <Router>
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/our-values" element={<OurValues />} />
        <Route path="/Time-line" element={<Timeline />} />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/Banner-container" element={<BannerContainer />} />
        <Route path="/Leadership" element={<LeadershipTeam />} />
        {/* <Route path='/bi-data&visulaization' element={<BiVisualization />} /> */}
        {/* <Route path='/data-management&data-goverance' element={<ManagementGov />} /> */}
        {/* <Route path='/dataplatforms-bigdata' element={<DataPlatforms />} /> */}
        <Route path="/datascience&analytics" element={<DataScience />} />
        <Route path="/cloud-data-services" element={<CloudData />} />
        {/* <Route path='/rpa' element={<RoboticAutomation />} /> */}
        <Route path="/managed-services" element={<ManagedServices />} />
        {/* <Route path='/testing-services' element={<TestingServices />} /> */}
        <Route path="/footer" element={<Footer />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/about-sss" element={<Aboutsss />} />
        <Route path="/success-stories" element={<SuccessStory />} />
        {/* <Route path='/bigdata' element={<BigData/>}/> */}
        <Route path="/ai-roadmap" element={<AiRoadmap />} />
        <Route path="/bigdata" element={<BigDataManged />} />
        <Route path="/bi-platform-migration" element={<BiPlatform />} />
        <Route path="/cloudera-cdp" element={<Cloudera />} />
        <Route
          path="/cloudera-managed-services"
          element={<ClouderaManaged />}
        />
        <Route path="/contract-abstraction" element={<ContractAbstraction />} />
        <Route path="/control-towers" element={<ControlTowers />} />
        <Route
          path="/data-analysis-managed-services"
          element={<DataAnalyticsManaged />}
        />
        <Route path="/data-goverance" element={<DataGovernance />} />
        <Route
          path="/data-science-as-a-service"
          element={<DataScienceService />}
        />
        <Route path="/data-strategy" element={<DataStrategy />} />
        <Route path="/industrial-iot" element={<IndustrialIot />} />
        <Route path="/manufacturing-intelligence" element={<Manufacturing />} />
        <Route
          path="/material-inflation-analytics"
          element={<MaterialInflation />}
        />
        <Route path="/ssrs-to-power-migration" element={<PowerBiMigration />} />
        <Route path="/snowflake-performance" element={<SnowFlake />} />
        <Route
          path="/snowflake-managed-services"
          element={<SnowflakeManaged />}
        />
        <Route path="/supply-chain-risk" element={<SupplyChain />} />
        <Route
          path="/ssrs-to-tableau-migration"
          element={<TableauMigration />}
        />
        <Route path="/data-engineering" element={<DataEngineering />} />
        <Route path="/data-analytics" element={<DataAnalytics />} />
        <Route path="/devops-services" element={<DevopsServices />} />
        <Route path="/data-science" element={<DataScience />} />
        <Route path="/process-development" element={<ProcessDevelopment />} />
        <Route path="/cloud-migration" element={<CloudMigration />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/app-development" element={<AppDevelopment />} />
        <Route path="/career-section" element={<CareerSection />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/our-products" element={<VideoGallery />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/career-section" element={<CareerSection />} />
        <Route path="/about-home" element={<AboutHome />} />
        <Route path="/why" element={<Why />} />
        <Route path="/services" element={<Services />} />
        <Route path="/whyy" element={<Whyy />} />
        <Route path="/Sliders" element={<Sliders />} />
      </Routes>
      {/* <ContactUs/> */}
      {/* <Footer/> */}
    </Router>
  );
}

export default App;
