

import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  border: 1px solid #ccc;
  background-color: #ffffff;
  padding: 10px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckmarkIcon = styled.span`
  font-size: 18px;
  ${'' /* margin-left: auto; */}
  color: #27ae60;
`;

const RobotCheckbox = () => {
  return (
    <CheckboxContainer>
      <Checkbox type="checkbox" />
      <span>I'm not a robot</span>
          {/* <CheckmarkIcon>&#10004;</CheckmarkIcon> */}
          <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/recapctha.png"  style={{width:"50px"}}></img>
    </CheckboxContainer>
  );
};

export default RobotCheckbox;