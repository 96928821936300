
import React from 'react';
import './TechnologyList.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const TechnologyList = () => {

    const webtechnologies = [
  // {
  //   src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/wordpress-logo.png',
  //   alt: 'WordPress Logo',
  // },
  // {
  //   src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/Webflow-Logo.png',
  //   alt: 'Webflow Logo',
      // },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/React-Logo.png',
    alt: 'React Logo',
  },
  
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/Angular-js-logo.png',
    alt: 'Angular Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/Bootstrap-Logo.png',
    alt: 'Bootstrap Logo',
      },
  {
    
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/Java-Logo.png',
    alt: 'Java Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/Laravel-Logo.png',
    alt: 'Laravel Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/Node-js-logo.png',
    alt: 'Node.js Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/PHP-logo.png',
    alt: 'PHP Logo',
      },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/Python-Logo.png',
    alt: 'Python Logo',
  },
  
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/VUE-logo.png',
    alt: 'Vue.js Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/HTML-Logo.png',
    alt: 'HTML Logo',
      },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/CSS-logo.png',
    alt: 'CSS Logo',
  },
      
  
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/jquery-Logo.png',
    alt: 'jQuery Logo',
  },

  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Web+Technologies/My-SQL-Logo.png',
    alt: 'MySQL Logo',
  },
    ];
    


      const mobiletechnologies = [
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Mobile+Technologies/Java-Logo+(1).png',
    alt: 'JAVA Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Mobile+Technologies/C%23-logo.png',
    alt: 'C## Logo',
  },
  { 
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Mobile+Technologies/React-Native.png',
    alt: 'React Native Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Mobile+Technologies/Flutter-logo.png',
    alt: 'Flutter Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Mobile+Technologies/Android-Studio.png',
    alt: 'Android Studio Logo',
  },

  ];
   const cloudtechnologies = [
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/cloud/aws.png',
    alt: 'AWS Logo',
  },
  {
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/cloud/Azure.png',
    alt: 'AZURE Logo',
  },
  { 
    src: 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/cloud/google+cloud.png',
    alt: 'GCP Logo',
  },
  

    ];
    

  const uxtechnologies = [
    {
    
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/UX-UV+technologies/React-Logo-1.png",
      alt: 'React Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/UX-UV+technologies/React-Native+(1).png",
      alt: 'React Native Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/UX-UV+technologies/VUE-logo+(1).png",
      alt: 'Vue Js'
    },
     
     
  ];
  const devopstechnologies = [
    {
    
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Deveops/git.png",
      alt: 'GIT Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Deveops/jenkins.png",
      alt: 'Jenkins Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Deveops/Docker.png",
      alt: 'Docker Logo'
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Deveops/ansible.png",
      alt: 'Ansible Logo'
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Deveops/nagios.png",
      alt: 'Nagios Logo'
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Deveops/kubernetes.png",
      alt: 'Kubernetes Logo'
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Deveops/terraform.png",
      alt: 'Terraform Logo '
    },
       
    //     {
    //        src: "images/kuber.png",
    //        alt:'Vue Js'
    //  },
    //      {
    //        src: "images/6.png",
    //        alt:'Vue Js'
    //  },
    //       {
    //        src: "images/kuber1.png",
    //        alt:'Vue Js'
    //  },
    //        {
    //        src: "images/2.png",
    //        alt:'Vue Js'
    //  },
         
  ];
   const ETLTools = [
    {
    
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/ETL/talend.png",
      alt: 'Talend Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/ETL/oracle+dataintegrator.png",
      alt: 'ODI Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/ETL/azure+datafactory.png",
      alt: 'Azure Data Factory Logo'
     },
     {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/ETL/informatica.png",
      alt: 'Informatica Logo'
    },
     
     
  ];
   const Database = [
    {
    
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Database/oracle+database.png",
      alt: 'Oracle SQL Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Database/mysql.png",
      alt: 'MYSQL Server Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Database/postgresql.png",
      alt: 'PostGres Sql Logo'
     },
     {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Database/mongodb.png",
      alt: 'Mongo db Logo'
     },
     {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Database/snowflake.png",
      alt: 'Snowflake Logo'
     },
     {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/Database/sql+server.png",
      alt: 'sql Server Logo'
     },
     
     
     
  ];

   const BITools = [
    {
    
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/BI/domo.png",
      alt: 'Domo Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/BI/oracle+bi.png",
      alt: 'OBIEE Logo',
    },
    {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/BI/powerbi.png",
      alt: 'Power Bi Logo'
     },
     {
      src: "https://sssbiimages.s3.ap-south-1.amazonaws.com/Technology+Logos/BI/tableau.png",
      alt: 'Tableau Logo'
     },
     
     
     
  ];
    return (
      <Tabs
      defaultActiveKey="et"
      id="uncontrolled-tab-example"
      className="mb-3 nav" style={{backgroundColor:"white"}}
        >
                         <Tab eventKey="et" title="ETL Tools">
  <div className="container">
  <div className="row">
    {ETLTools.map((logo, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-2 mb-4">
        <div className="logo-box">
          <img
            src={logo.src}
            alt={logo.alt}
            className="img-fluid img-with-animation skip-lazy black-logos animated-in"
            data-delay="0"
            data-animation="fade-in"
          />
        </div>
      </div>
    ))}
  </div>
</div>

        </Tab>   
           

          <Tab eventKey="bi" title="BI Tools">
  <div className="container">
  <div className="row">
    {BITools.map((logo, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-2 mb-4">
        <div className="logo-box">
          <img
            src={logo.src}
            alt={logo.alt}
            className="img-fluid img-with-animation skip-lazy black-logos animated-in"
            data-delay="0"
            data-animation="fade-in"
          />
        </div>
      </div>
    ))}
  </div>
</div>

        </Tab>
            
                
                          <Tab eventKey="db" title="DataBase">
  <div className="container">
  <div className="row">
    {Database.map((logo, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-2 mb-4">
        <div className="logo-box">
          <img
            src={logo.src}
            alt={logo.alt}
            className="img-fluid img-with-animation skip-lazy black-logos animated-in"
            data-delay="0"
            data-animation="fade-in"
          />
        </div>
      </div>
    ))}
  </div>
</div>

        </Tab>
        
                <Tab eventKey="ux/ui" title="UX/UI Tools">
  <div className="container">
  <div className="row">
    {uxtechnologies.map((logo, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-2 mb-4">
        <div className="logo-box">
          <img
            src={logo.src}
            alt={logo.alt}
            className="img-fluid img-with-animation skip-lazy black-logos animated-in"
            data-delay="0"
            data-animation="fade-in"
          />
        </div>
      </div>
    ))}
  </div>
</div>

</Tab>

        
               <Tab eventKey="devops" title="Devops Tools">
  <div className="container">
  <div className="row">
    {devopstechnologies.map((logo, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-2 mb-4">
        <div className="logo-box">
          <img
            src={logo.src}
            alt={logo.alt}
            className="img-fluid img-with-animation skip-lazy black-logos animated-in"
            data-delay="0"
            data-animation="fade-in"
          />
        </div>
      </div>
    ))}
  </div>
</div>

        </Tab>
                       <Tab eventKey="ct" title="Cloud Technologies">
  <div className="container">
  <div className="row">
    {cloudtechnologies.map((logo, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-2 mb-4">
        <div className="logo-box">
          <img
            src={logo.src}
            alt={logo.alt}
            className="img-fluid img-with-animation skip-lazy black-logos animated-in"
            data-delay="0"
            data-animation="fade-in"
          />
        </div>
      </div>
    ))}
  </div>
</div>

        </Tab>

        
            
            <Tab eventKey="wt" title="Web Technologies">
  <div className="container tech">
  <div className="row tech">
    {webtechnologies.map((logo, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-2 mb-4">
        <div className="logo-box">
          <img
            src={logo.src}
            alt={logo.alt}
            className="img-fluid img-with-animation skip-lazy black-logos animated-in"
            data-delay="0"
            data-animation="fade-in"
          />
        </div>
      </div>
    ))}
  </div>
</div>

</Tab>
         <Tab eventKey="mt" title="Mobile Technologies">
  <div className="container">
  <div className="row">
    {mobiletechnologies.map((logo, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-2 mb-4">
        <div className="logo-box">
          <img
            src={logo.src}
            alt={logo.alt}
            className="img-fluid img-with-animation skip-lazy black-logos animated-in"
            data-delay="0"
            data-animation="fade-in"
          />
      </div>
      </div>
    ))}
  </div>
</div>

</Tab>

           
          
             
      
            
           
            </Tabs>
  );
};

export default TechnologyList;







