import React from "react";
import "./ServicesStyle.css";
import Footer from "../components/Footer";
import TechnologyList from "../components/TechnologyList";
import DataAnalyticsCarousel from "./DataAnalyticsCarousel";
import DataAnalyticsCarousel1 from "./DataAnalyticsCarousel1";
import { Link } from "react-router-dom";
import Footers from "../components/Footers";

const DataAnalytics = () => {
  return (
    <div>
      {/* <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/data-analytics/Data-Analytics1.jpg" alt="Nature" class="service-header-img"/>
<div class="container-fluid">
  <div class="text-whitedata">
  <h1 class ="text-size-head-white" style={{fontSize:"44px"}}>Data Analytics</h1>
<h4 class="text-size-head-white-h4">Unlocking Insights, Driving Decisions: Leading the Way in Data Analytics</h4>
  </div>
</div> */}

      <div
        className="container-fluid serviceDisplay"
        style={{ marginTop: "15vh" }}
      >
        <div className="row  ">
          <div className="col">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Service+page+sliders/Website+Sliders+Service+Page-04.jpg"
              alt="Nature"
              class="service-header-imgs-service"
            />
          </div>
        </div>
      </div>

      <div class="container-fluid ">
        <div class="text-whitedata whitedata">
          <h1 class="text-size-head-white serviceDisplay-tag">
            Data Analytics{" "}
          </h1>
          <h4 class="text-size-head-white-h4 serviceDisplay-tags">
            Unlocking Insights, Driving Decisions: Leading the Way in Data
            Analytics
          </h4>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="insight-matters">
            {" "}
            <h1>Insights that matter</h1>
            <p class="governance-data-size">
              Organizations must harness their continuous data generation to
              drive smarter business decisions, unlock new opportunities, and
              proactively manage organizational risks.
            </p>
            <p class="governance-data-size">
              At SSS, we collaborate with you to craft your analytics roadmap,
              pinpointing lucrative business prospects and enabling you to
              monetize your data using cutting-edge analytics technologies.
            </p>
            {/* <div class="bt-start-now">  <a class="btn-start" type="button" value="START NOW" placeholder="START NOW" href="/contact" target=""><p>START NOW</p></a>
            </div> */}
            <div className="text-center">
              <Link
                className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
                target="_self"
                to="/contact"
              >
                <span className="fusion-button-text">START NOW</span>
                {/* <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i> */}
              </Link>
            </div>
          </div>{" "}
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="governance-data">
            <h1>Enhance Decision-Making through Data Analytics</h1>

            <p class="governance-data-size">
              Advancements in Computing and Enhanced Data Accessibility Propel
              the Evolution of Data Analytics. This Discipline Sparks Business
              Innovation, Enabling In-Depth Analysis of Expansive Datasets for
              Valuable Insights. When Coupled with AI and ML, It Drives
              Operational Efficiency, Informs Sound Decision-Making, and
              Accelerates Overall Business Growth.
            </p>
            <br />
            <h6 class="challenges-size">
              Data analytics solutions can assist businesses in tackling
              challenges and Businesses require suitable Data Analytics
              capabilities to leverage data for informed decision-making and
              enhance customer experiences. The ideal platform offers are{" "}
            </h6>
          </div>

          <div class="col-md-6">
            <div class="challenges">
              {/* <h6 class="challenges-size">Data analytics solutions can assist businesses in tackling challenges such as:</h6> */}

              <ul>
                <li class="li-flex" style={{ paddingTop: "23px" }}>
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Uncovering Precise Insights from Data to Enhance
                      Decision-Making
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Discovering New Opportunities Through Rapid Proof of
                      Concept (POC) Analysis Across Diverse Datasets
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Streamlining Operations to Minimize Revenue Loss
                    </p>
                  </div>
                </li>
                {/* <li class="li-flex">
    <p style={{paddingTop:"0px",marginBottom:"30px"}}> <img src="" alt="" class="logo-img"/>
    Streamlining Operations to Minimize Revenue Loss
    </p></li> */}
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Elevating Customer Experience and Mitigating Customer
                      Churn
                    </p>
                  </div>
                </li>

                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Mitigating Risks, Including Fraudulent Transactions
                    </p>
                  </div>
                </li>
                {/* <p style={{paddingTop:"0px"}}>  <img src="" alt="" class="logo-img"/>
   Mitigating Risks, Including Fraudulent Transactions
    </p> */}
              </ul>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="interactive">
              <br />
              <ul>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Ensure Elements Such as Data Trust, Governance, and
                      Quality.
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Efficient Storage of Complex Data and 24/7 Uptime
                      Assurance.
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Key Proficiencies Including Data Integration, Data
                      Preparation
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Data Exploration, and Data Visualization
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Continuous Uptime and Minimal Downtime.
                    </p>
                  </div>
                </li>

                <br />
              </ul>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row service-row1">
              <div class="bigdata">
                <h1>Data Analytics Service Offerings</h1>
              </div>
              <DataAnalyticsCarousel />
            </div>
          </div>

          {/* <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1>Data Analytics Service Offerings</h1>
</div>

<div class="slide-show">
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

    <div class="carousel-item active">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size" >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/Data-Science-Advisory-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Analytics Strategy Guidance</h5>
      <ul class="card-text" >
        <li>Craft an Analytics Strategy</li>
        <li>Identify Use Cases</li>
        <li>Tailor a Data Analytics Roadmap</li>
        <li>Build a Data Analytics Framework</li>
        <li>Explore Data and Select Relevance</li>
        <li>Create Scalable Data Analytics Processes</li>
       </ul> </div>
  </div>
  <div class="card bi-card-size" >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Innovative Data Analytics Offerings</h5>
      <ul class="card-text" >
      <li>Develop analytics strategy</li>
      <li>Install and configure the analytics platform</li>
      <li>Develop and deploy exploratory analytics models</li>
      <li>Implement predictive analytics solutions</li>
   <li>Create an analytical platform for customer insights and a 360-degree view</li>
       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/AI-ML-Development-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Analytics Development Services</h5>
      <ul class="card-text" >
      <li>Offer Customized Customer Experience Solutions</li>
      <li>Tailor AI Solutions to Individual Customer Requirements</li>
      <li>Integrate Chatbots and Automated Assistants for Communication</li>
      <li>Leverage Enterprise Application Engineering for Data Extraction</li>
       </ul>
    </div>
  </div>


      </div>
    </div>


    <div class="carousel-item">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Innovative Data Analytics Offerings</h5>
      <ul class="card-text" >
      <li>Develop analytics strategy</li>
      <li>Install and configure the analytics platform</li>
      <li>Develop and deploy exploratory analytics models</li>
      <li>Implement predictive analytics solutions</li>
   <li>Create an analytical platform for customer insights and a 360-degree view</li>
       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/AI-ML-Development-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Analytics Development Services</h5>
      <ul class="card-text" >
      <li>Offer Customized Customer Experience Solutions</li>
      <li>Tailor AI Solutions to Individual Customer Requirements</li>
      <li>Integrate Chatbots and Automated Assistants for Communication</li>
      <li>Leverage Enterprise Application Engineering for Data Extraction</li>
       </ul>
    </div>
  </div>

  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/Managed-Services.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Managed Data Analytics Services</h5>
      <ul class="card-text">
      <li>Offer Engineering Support to Customers</li>
      <li>Conduct Thorough Testing to Identify Bugs</li>
      <li>Collaborate with the Engineering Team to Report Bugs, Update, and Prepare Documentation</li>
      <li>Administer Data Analytics Products and Services</li>
       </ul>
    </div>
  </div>


      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div></div>
</div></div>  */}

          <div class="container">
            <div class="row">
              <div class="mg-management">
                <h1>Data Analytics – Programs | Frameworks | Accelerators</h1>
              </div>
              <DataAnalyticsCarousel1 />
            </div>
          </div>

          {/* <div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Data Analytics – Programs | Frameworks | Accelerators</h1>
</div>

<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row" >
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Data Analytics Vigilance Program </h5>
              <ul class="card-text">
              <li>Provision and Manage Infrastructure for analytics Solutions</li>
              <li>Refine and Optimize Models, Achieve KPIs, Identify Issues, and Track Changes</li>
              <li>Educate on Big Data Technologies for Informed Adoption</li>
              <li>Ensure Data Quality and Maintain Data Catalog</li>
              <li>Provide Support and Maintenance Services</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Transparent Analytics Framework</h5>
              <ul class="card-text">
              <li>Promotes Transparency in Analytics Decision-Making</li>
              <li>Especially Beneficial in Healthcare, Finance, and Legal Sectors</li>
              <li>Incorporates Data Pre-processing</li>
              <li>Prioritizes Clear and Succinct Explanations</li>
              <li>Fosters Trust Among Users and Stakeholders</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1" >

              <h5 class="card-title">Text Extraction from Documents using NLP</h5>
              <ul class="card-text">
              <li>Manual Contract Management is Time-Consuming, Costly, and Risky</li>
              <li>Contract Abstraction Identifies Key Terms, Clauses, Dates, and Relevant Data</li>
              <li>NLP Techniques Facilitate Information Extraction</li>
              <li>Web Scraping Involves Data Extraction</li>

                </ul>
               </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-item ">
      <div class="row" >
      <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Transparent Analytics Framework</h5>
              <ul class="card-text">
              <li>Promotes Transparency in Analytics Decision-Making</li>
              <li>Especially Beneficial in Healthcare, Finance, and Legal Sectors</li>
              <li>Incorporates Data Pre-processing</li>
              <li>Prioritizes Clear and Succinct Explanations</li>
              <li>Fosters Trust Among Users and Stakeholders</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1" >

              <h5 class="card-title">Text Extraction from Documents using NLP</h5>
              <ul class="card-text">
              <li>Manual Contract Management is Time-Consuming, Costly, and Risky</li>
              <li>Contract Abstraction Identifies Key Terms, Clauses, Dates, and Relevant Data</li>
              <li>NLP Techniques Facilitate Information Extraction</li>
              <li>Web Scraping Involves Data Extraction</li>

                </ul>
               </div>
          </div>
        </div>


        <div class="col-lg-4">

          <div class="card text " >
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Interactive Analytics Solutions</h5>
              <ul class="card-text">
              <li>Identify the Customer Business and provide Tailored solutions using advanced Conversational Analytics solutions</li>
              <li>Developing Intelligent Digital Assistants</li>
              <li>Enhancing User Experience</li>

               </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div></div> */}

          <div class="container">
            <div class="row" style={{ marginTop: "0px", marginBottom: "30px" }}>
              <div class="bi-service">
                <h1>Our Technology Expertises</h1>
              </div>
              <TechnologyList />
            </div>
          </div>
        </div>
      </div>

      <Footers />
    </div>
  );
};

export default DataAnalytics;
