import React from "react";
import "./ServicesStyle.css";
import Footer from "../components/Footer";
import TechnologyList from "../components/TechnologyList";
import CloudDataCarousel from "./CloudDataCarousel";
import CloudDataCarousel1 from "./CloudDataCarousel1";
import { Link } from "react-router-dom";
import Footers from "../components/Footers";
const CloudData = () => {
  return (
    <div>
      <div
        className="container-fluid serviceDisplay"
        style={{ marginTop: "15vh" }}
      >
        <div className="row  ">
          <div className="col">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Service+page+sliders/Website+Sliders+Service+Page-09.jpg"
              alt="Nature"
              class="service-header-imgs-service"
            />
          </div>
        </div>
      </div>

      <div class="container-fluid ">
        <div class="text-whitedata whitedata">
          <h1 class="text-size-head-white serviceDisplay-tag">Cloud Data </h1>
          <h4 class="text-size-head-white-h4 serviceDisplay-tags">
            Innovating Cloud Data Solutions for Tomorrow's Challenges
          </h4>
        </div>
      </div>

      <div class="container total-bg-color">
        <div class="row">
          <div class="insight-matters">
            {" "}
            <h1>Insights that matter</h1>
            <p class="governance-data-size">
              The cloud plays an indispensable role in driving enterprise-wide
              digital transformation, primarily due to its unmatched capacity
              for scalability.
            </p>
            <p class="governance-data-size">
              SSS services are meticulously designed to empower our clients in
              achieving both their present and future business aspirations. We
              accomplish this through the invaluable expertise of our highly
              skilled team, comprised of seasoned cloud data specialists and
              accomplished professionals with decades of combined experience.
            </p>
            {/* <div class="bt-start-now">
             <a class="btn-start" type="button" value="START NOW" placeholder="START NOW" href="/contact" target=""><p>START NOW</p></a>
            </div> */}
            <div className="text-center">
              <Link
                className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
                target="_self"
                to="/contact"
              >
                <span className="fusion-button-text">START NOW</span>
                {/* <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i> */}
              </Link>
            </div>
          </div>{" "}
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="governance-data">
            <h1>The Escalating Demand for Cloud-Based Data Interpretation</h1>

            <p class="governance-data-size">
              The cloud is the cornerstone of enterprise-wide digital
              transformation, and at SSS, we are dedicated to assisting our
              clients in harnessing its power for rapid business growth,
              scalability, modernization of applications, and the successful
              attainment of their objectives.{" "}
            </p>
            <br />
            <h6 class="challenges-size">
              Cloud technology is a catalyst for fostering agility and
              flexibility in the way organizations operate and conduct bussiness
              and During the Covid-19 crisis, businesses quickly adopted cloud
              technology for their digital transformation journey. This shift
              not only enabled remote work but also unlocked a multitude of
              substantial benefits.:
            </h6>
          </div>

          <div class="col-md-6">
            <div class="challenges ">
              {/* <h6 class="challenges-size">Cloud technology is a catalyst
         for fostering agility and flexibility in the way organizations operate and conduct business.</h6> */}
              <br />
              <ul>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Heightened intricacy
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Rising Amount, Diversity and Data
                </p>
                <p>
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Adapting to Contemporary Business
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Costs Associated with Maintenance
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Requirement for Scalability
                </p>
              </ul>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="interactive ">
              {/* <h6 class="challenges-size">During the Covid-19 crisis, businesses quickly adopted cloud technology for their digital transformation journey.
        This shift not only enabled remote work but also unlocked a multitude of substantial benefits.:</h6> */}
              <br />
              <ul>
                <p class="interactive-margin1">
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Reduces IT and infrastructure cost​
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Scalability Collaboration efficiency​
                </p>
                <p>
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Environmental Sustainability​
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Security and mobility​
                </p>
                <p>
                  {" "}
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                    alt=""
                    class="logo-img"
                  />
                  Remote Accessibility and Updates
                </p>

                <br />

                <br />
              </ul>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row service-row1">
              <div class="bigdata">
                <h1>Cloud Data Expertise Offerings​</h1>
              </div>
              <CloudDataCarousel />
            </div>
          </div>

          {/* <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1>Cloud Data Expertise Offerings​</h1>
</div>

<div class="slide-show">
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

    <div class="carousel-item active">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size"  >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Advisory-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Cloud Strategy Guidance​</h5>
      <ul class="card-text " >
        <li>Evaluating Current Infrastructure and Applications​</li>
        <li>Selecting the Appropriate Cloud Platform, Services, and Migration Strategy​</li>
        <li>Crafting Cloud Infrastructure and Application Architecture Designs​​​</li>
        <li>Adhering to Leading Industry Standards and Best Practices​</li>
       </ul> </div>
  </div>
  <div class="card bi-card-size" >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-BI.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black">Data Science and BI ​</h5>
      <ul class="card-text" >
      <li>Deliver Solutions Utilizing Relevant Cloud Services</li>
      <li>Acquire Services in Accordance with the Roadmap</li>
      <li>Harness Cloud Resources for Business Intelligence and Data Science Solutions</li>
      <li>Integrate In-House and Cloud-Based Resources Effectively​</li>


       </ul>
        </div>
  </div>
  <div class="card bi-card-size ">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black" >Cloud ​Managed Services​</h5>
      <ul class="card-text" >
      <li>Facilitates and Enhances DevOps Practices​​</li>
      <li>Efficiently Manages AWS, Azure, or GCP Cloud Platforms​</li>
      <li>Handles Large Data Volumes with Ease​</li>
      <li>Leverages Advanced Analytics Tools</li>
      <li>Offers Scalable and Flexible Infrastructure for Data Analytics​</li>

       </ul>
    </div>
  </div>
 </div> </div>

 <div class="carousel-item">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-BI.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Data Science and BI </h5>
      <ul class="card-text" >
      <li>Deliver Solutions Utilizing Relevant Cloud Services</li>
      <li>Acquire Services in Accordance with the Roadmap</li>
      <li>Harness Cloud Resources for Business Intelligence and Data Science Solutions</li>
      <li>Integrate In-House and Cloud-Based Resources Effectively​</li>


       </ul>
        </div>
  </div>
  <div class="card bi-card-size ">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black" >Cloud ​Managed Services​</h5>
      <ul class="card-text" >
      <li>Facilitates and Enhances DevOps Practices​​</li>
      <li>Efficiently Manages AWS, Azure, or GCP Cloud Platforms​</li>
      <li>Handles Large Data Volumes with Ease​</li>
      <li>Leverages Advanced Analytics Tools</li>
      <li>Offers Scalable and Flexible Infrastructure for Data Analytics​</li>

       </ul>
    </div>
  </div>

  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Migration.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Cloud Migration Assistance</h5>
      <ul class="card-text">
      <li>Thorough Exploration of Mainframe Applications, Batches, and Datasets​</li>
      <li>Migration to Big Data Platforms and Decommissioning of Mainframe</li>
      <li>Archiving Legacy Data on Hadoop and Elasticsearch</li>
      <li>Modernizing ETL (Extract, Transform, Load) Processes with Spark</li>

       </ul>
    </div>
  </div>
 </div></div>
</div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div></div>
</div></div>  */}

          <div class="container">
            <div class="row">
              <div class="mg-management">
                <h1>
                  Cloud Data Practice – Programs | Frameworks | Accelerators
                </h1>
              </div>
              <CloudDataCarousel1 />
            </div>
          </div>

          {/* <div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Cloud Data Practice – Programs | Frameworks | Accelerators</h1>
</div>

<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row" >
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Cloud Data Strategy Roadmap Initiative​ </h5>
              <ul class="card-text">
              <li>Data Infrastructure and Storage Optimizatio​</li>
              <li>Data Integration and Streamlined Processing</li>
              <li>Data Governance and Security Enhancement​</li>
              <li>Analytics and Business Intelligence Empowerment ​</li>
              <li>Advanced Analytics and Machine Learning Integration​</li>
              <li>Cloud Provider and Technology Selection</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Cloud Data Platform Deployment Initiative​</h5>
              <ul class="card-text">
              <li>Comprehensive Data Landscape Assessment and Cloud Platform Selection</li>
              <li>Leverage a Range of Cloud Service Models</li>
              <li>Efficient Deployment of Cloud-Based BI, ETL, and Data Lake Solutions</li>
              <li>Configuration, Deployment, and Seamless Data Migration​</li>
              <li>Data Quality Assurance and Validation Measures​</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >

              <h5 class="card-title">Cloud Data Quality Assurance Initiative​ </h5>
              <ul class="card-text">
              <li>Deployment and Ongoing Suppor​</li>
              <li>Monitoring and Incident Management​</li>
              <li>Issue Resolution and Root Cause Analysis​</li>
              <li>Performance Analysis and Optimization</li>
              <li>Stakeholder Communication​</li>
              <li>Access to Experienced Resources​</li>

                </ul>
               </div>
          </div>
        </div>
      </div>
    </div>



    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-card-body-colors1" >
            <h5 class="card-title">Cloud Data Platform Deployment Initiative​</h5>
              <ul class="card-text">
              <li>Comprehensive Data Landscape Assessment and Cloud Platform Selection</li>
              <li>Leverage a Range of Cloud Service Models</li>
              <li>Efficient Deployment of Cloud-Based BI, ETL, and Data Lake Solutions</li>
              <li>Configuration, Deployment, and Seamless Data Migration​</li>
              <li>Data Quality Assurance and Validation Measures​</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >

              <h5 class="card-title">Cloud Data Quality Assurance Initiative​ </h5>
              <ul class="card-text">
              <li>Deployment and Ongoing Suppor​</li>
              <li>Monitoring and Incident Management​</li>
              <li>Issue Resolution and Root Cause Analysis​</li>
              <li>Performance Analysis and Optimization</li>
              <li>Stakeholder Communication​</li>
              <li>Access to Experienced Resources​</li>


                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1" >
            <h5 class="card-title">Legacy System Transformation Initiative​</h5>
              <ul class="card-text">
             <li>Determine the Appropriate Technology Stack</li>
             <li>Conduct Thorough Assessment of Legacy Systems​</li>
             <li>Transfer Enterprise Data Warehousing (EDW) to the Cloud</li>
             <li>Reconfigure ETL (Extract, Transform, Load) Processes</li>
             <li>Establish Modernization Approach and Data Migration Strategy​</li>



              </ul>
           </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div></div> */}

          <div class="container">
            <div class="row" style={{ marginTop: "0px", marginBottom: "30px" }}>
              <div class="mg-management">
                <h1>Our Technology Expertises</h1>
              </div>

              <TechnologyList />
            </div>
          </div>
        </div>
      </div>

      {/* <div class="">
    <div class="container-fluid why-sss-bg" >
        <div class="row">
            <div class="section-head col-md-3" style={{marginTop:"50px",textAlign:"center"}}>
            <h1 className='chose-why'><span>Why Choose <br/><strong class="sss-color">SSS</strong></span></h1><br/>
<div class="logo-sss-rotate"><img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="choose-logo"  /></div>
 </div>

 <div class="col-md-3 col-sm-6" style={{marginLeft:"-10px"}}>
    <div><img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/why_sss/Client-FirstApproach.png" alt="" class="choose-sss" /></div>
    <div class="client-head"><h5>Client FirstApproach</h5></div>
    <p class="why-ss-p">We take pride in our exceptional track record of nurturing long-term client relationships, and our commitment to resolving customer issues the right way is at the core of our values.</p>
 </div>

 <div class="col-md-3 col-sm-6">
    <div><img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/why_sss/Engineering-Expertise.png" alt="" class="choose-sss" /></div>
    <div class="client-head"><h5>Engineering Expertise and Innovation Focus</h5></div>
    <p class="why-ss-p">Through continuous research and exploration of cutting-edge technologies, our practices consistently deliver added value to our clients and partners.</p>
 </div>

 <div class="col-md-3 col-sm-6">
    <div><img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/why_sss/DeliveryMethodology.png" alt="" class="choose-sss" /></div>
    <div class="client-head"><h5>Delivery Methodology</h5></div>
    <p class="why-ss-p">Our proficiency in data analytics delivery is demonstrated through a meticulously designed methodology and expertise in various frameworks, including Waterfall, Scrum, ITIL, DevOps, and more.</p>
 </div>

            <div class="row" style={{ marginTop: "20px" }}>
              <div class="col-md-3 col-sm-6"></div>
 <div class="col-md-3 col-sm-6">
    <div><img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/why_sss/ProcessExcellence.png" alt="" class="choose-sss" /></div>
    <div class="client-head"><h5>Process
Excellence</h5></div>
    <p class="why-ss-p">Achieved comprehensive process excellence across all facets, encompassing security, documentation, intellectual property protection, audits, and more.</p>
 </div>
 <div class="col-md-3 col-sm-6">
    <div ><img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/why_sss/Strong-Produc-tPartnerships.png" alt="" class="choose-sss" /></div>
    <div class="client-head"><h5>Strong Product
Partnerships
</h5></div>
    <p class="why-ss-p">Exceptional history of collaborating with Data and Analytics Product companies to effectively execute client projects.</p>
 </div>
 <div class="col-md-3 col-sm-6">
    <div><img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/why_sss/Our-People.png" alt="" class="choose-sss" /></div>
    <div class="client-head"><h5>Our
People</h5></div>
    <p class="why-ss-p">We take pride in our diverse and inclusive talent pool, which nurtures innovation, encourages collaboration, promotes continuous learning, fuels success, and consistently delivers exceptional results for our clients.</p>
 </div>
 </div>
        </div>
    </div>
</div> */}

      {/* <ContactUss/> */}
      <Footers />
    </div>
  );
};

export default CloudData;
