import React from "react";
import "./ServicesStyle.css";
import Footer from "../components/Footer";
import TechnologyList from "../components/TechnologyList";
import DataScienceCarousel from "./DataScienceCarousel";
import DataScienceCarousel1 from "./DataScienceCarousel1";
import { Link } from "react-router-dom";
import Footers from "../components/Footers";
const DataScience = () => {
  return (
    <div>
      {/* <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/datascience.jpg" alt="Nature" class="service-header-img"/>
<div class="container-fluid">
  <div class="text-whitedata">
  <h1 class ="text-size-head-white" style={{fontSize:"44px"}}>Data Science<br/>/AI/ML</h1>
<h4 class="text-size-head-white-h4">Unlocking the Future with Data Science, AI, and ML Excellence</h4>
  </div>
</div> */}

      <div
        className="container-fluid serviceDisplay"
        style={{ marginTop: "15vh" }}
      >
        <div className="row  ">
          <div className="col">
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Service+page+sliders/Website+Sliders+Service+Page-02.jpg"
              alt="Nature"
              class="service-header-imgs-service"
            />
          </div>
        </div>
      </div>

      <div class="container-fluid ">
        <div class="text-whitedata whitedata">
          <h1 class="text-size-head-white serviceDisplay-tag">
            Data Science /AI/ML
          </h1>
          <h4 class="text-size-head-white-h4 serviceDisplay-tags">
            Unlocking the Future with Data Science, AI, and ML Excellence
          </h4>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="insight-matters">
            {" "}
            <h1>Insights that matter</h1>
            <p class="governance-data-size">
              Organizations must harness their continuous data generation to
              drive smarter business decisions, unlock new opportunities, and
              proactively manage organizational risks.
            </p>
            <p class="governance-data-size">
              At SSS, we collaborate with you to craft your analytics roadmap,
              pinpointing lucrative business prospects and enabling you to
              monetize your data using cutting-edge analytics technologies.
            </p>
            {/* <div class="bt-start-now">   <a class="btn-start" type="button" value="START NOW" placeholder="START NOW" href="/contact" target=""><p>START NOW</p></a>
            </div> */}
            <div className="text-center">
              <Link
                className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
                target="_self"
                to="/contact"
              >
                <span className="fusion-button-text">START NOW</span>
                {/* <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i> */}
              </Link>
            </div>
          </div>{" "}
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="governance-data">
            <h1>Enhance Decision-Making through Data Science, AI, and ML</h1>

            <p class="governance-data-size">
              Progress in computing and increased data accessibility have
              propelled the expansion of Data Science. This field fuels business
              innovation, enabling the examination of vast datasets to extract
              actionable intelligence. When combined with Artificial
              Intelligence (AI), Machine Learning (ML), and Deep Learning (DL),
              it greatly enhances efficiency, decision-making, and overall
              business expansion{" "}
            </p>
            <br />
            <h6 class="challenges-size">
              Data Science solutions can aid businesses in addressing challenges
              and Businesses demand appropriate Data Science and AI, ML
              capabilities to harness data for informed decision-making and
              elevate customer experiences. The ideal platform provides:
            </h6>
          </div>

          <div class="col-md-6">
            <div class="challenges">
              {/* <h6 class="challenges-size">Data Science solutions can aid businesses in addressing challenges such as:</h6> */}

              <ul>
                <li
                  class="li-flex"
                  style={{ paddingTop: "40px", marginTop: "2px" }}
                >
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Uncovering Precise Insights from Data to Enhance
                      Decision-Making
                    </p>
                  </div>
                </li>

                <li class="li-flex sci-a5">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Mitigating Risks, Including Fraudulent Transactions
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Elevating Customer Experience and Mitigating Customer
                      Churn
                    </p>
                  </div>
                </li>

                <li class="li-flex sci-a3">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Streamlining Operations to Minimize Revenue Loss
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Discovering New Opportunities Through Rapid Proof of
                      Concept (POC) Analysis Across Diverse Datasets
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="interactive">
              <br />
              <ul>
                <li class="li-flex" style={{ paddingTop: "17px" }}>
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <br />
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Key Proficiencies Including Data Integration, Data
                      Preparation
                    </p>
                  </div>
                </li>

                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Data Exploration, and Data Visualization.
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Ensure Elements Such as Data Trust, Governance, and
                      Quality.
                    </p>
                  </div>
                </li>
                <li class="li-flex">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Efficient Storage of Complex Data and 24/7 Uptime
                      Assurance.
                    </p>
                  </div>
                </li>
                <li class="li-flex sci-b5">
                  <div>
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/footer_logo.gif"
                      alt=""
                      class="logo-img"
                    />
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      {" "}
                      Continuous Uptime and Minimal Downtime.
                    </p>
                  </div>
                </li>

                <br />
              </ul>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row service-row1">
              <div class="bigdata">
                <h1>Data Science/AI/ML Service Offerings</h1>
              </div>
              <DataScienceCarousel />
            </div>
          </div>

          {/* <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1>Data Science/AI/ML Service Offerings</h1>
</div>

<div class="slide-show">
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

    <div class="carousel-item active">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size" >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/Data-Science-Advisory-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Science Strategy Guidance</h5>
      <ul class="card-text" >
        <li>Formulate an Analytics Strategy</li>
        <li>Define Use Cases</li>
        <li>Customize a Data Science/AI Roadmap</li>
        <li>Establish a Data Science Framework</li>
        <li>Data Exploration and Selection</li>
        <li>Develop Scalable Data Science and AI Processes</li>
       </ul> </div>
  </div>
  <div class="card bi-card-size" >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Innovative Data Science/AI/ML Offerings</h5>
      <ul class="card-text" >
      <li>Formulate Data Science/AI/ML Services Strategy</li>
      <li>Install and Configure the Data Science/AI/ML Services Platform</li>
      <li>Create and Deploy Exploratory Data Science/AI/ML Models</li>
      <li>Implement Predictive Data Science/AI/ML Solutions</li>

       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/AI-ML-Development-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >AI/ML Solution Development Services</h5>
      <ul class="card-text" >
      <li>Offer Customized Customer Experience Solutions</li>
      <li>Tailor AI Solutions to Individual Customer Requirements</li>
      <li>Integrate Chatbots and Automated Assistants for Communication</li>
      <li>Leverage Enterprise Application Engineering for Data Extraction</li>
       </ul>
    </div>
  </div>


      </div>
    </div>


    <div class="carousel-item">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Innovative Data Science/AI/ML Offerings</h5>
      <ul class="card-text" >
      <li>Formulate Data Science/AI/ML Services Strategy</li>
      <li>Install and Configure the Data Science/AI/ML Services Platform</li>
      <li>Create and Deploy Exploratory Data Science/AI/ML Models</li>
      <li>Implement Predictive Data Science/AI/ML Solutions</li>

       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/AI-ML-Development-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >AI/ML Solution Development Services</h5>
      <ul class="card-text" >
      <li>Offer Customized Customer Experience Solutions</li>
      <li>Tailor AI Solutions to Individual Customer Requirements</li>
      <li>Integrate Chatbots and Automated Assistants for Communication</li>
      <li>Leverage Enterprise Application Engineering for Data Extraction</li>
       </ul>
    </div>
  </div>

  <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/dataScience/Managed-Services.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Managed Data Science Services</h5>
      <ul class="card-text">
      <li>Offer Engineering Support to Customers</li>
      <li>Conduct Thorough Testing to Identify Bugs</li>
      <li>Collaborate with the Engineering Team to Report Bugs, Update, and Prepare Documentation</li>
      <li>Administer Data Science Products and Services</li>
       </ul>
    </div>
  </div>


      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div></div>
</div></div>  */}

          <div class="container">
            <div class="row">
              <div class="mg-management">
                <h1>
                  Data Science/AI/ML – Programs | Frameworks | Accelerators
                </h1>
              </div>
              <DataScienceCarousel1 />
            </div>
          </div>

          {/* <div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Data Science/AI/ML – Programs | Frameworks | Accelerators</h1>
</div>

<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row" >
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Data Science Vigilance Program </h5>
              <ul class="card-text">
              <li>Provision and Manage Infrastructure for AI/ML Solutions</li>
              <li>Refine and Optimize Models, Achieve KPIs, Identify Issues, and Track Changes</li>
              <li>Educate on Big Data Technologies for Informed Adoption</li>
              <li>Ensure Data Quality and Maintain Data Catalog</li>
              <li>Provide Support and Maintenance Services</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Transparent AI Framework</h5><br/>
              <ul class="card-text">
              <li>Promotes Transparency in AI Decision-Making</li>
              <li>Especially Beneficial in Healthcare, Finance, and Legal Sectors</li>
              <li>Incorporates Data Pre-processing</li>
              <li>Prioritizes Clear and Succinct Explanations</li>
              <li>Fosters Trust Among Users and Stakeholders</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1" >

              <h5 class="card-title">Text Extraction from Contracts using NLP</h5>
              <ul class="card-text">
              <li>Manual Contract Management is Time-Consuming, Costly, and Risky</li>
              <li>Contract Abstraction Identifies Key Terms, Clauses, Dates, and Relevant Data</li>
              <li>NLP Techniques Facilitate Information Extraction</li>
              <li>Web Scraping Involves Data Extraction</li>

                </ul>
               </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Interactive AI Solutions</h5>
              <ul class="card-text">
              <li>Identify the Customer Business and provide Tailored solutions using advanced Conversational AI solutions</li>
              <li>Developing Intelligent Digital Assistants</li>
              <li>Enhancing User Experience</li>

               </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text  ">
            <div class="card-body cloud-card-body-colors1"  >
              <h5 class="card-title">Intelligent Automation</h5>
              <ul class="card-text">
              <li>Intelligent Enterprise Consulting to Help Organizations plot
                the Automation journey using various Data Science Technologies such as R and Python</li>
              <li>Deploying Intelligent Bots on Automation Platforms</li>
              <li>Leveraging AI and Machine Learning</li>

              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1">
              <h5 class="card-title">AI / ML Strategy</h5>
              <ul class="card-text">
              <li>Evaluate Business Objectives and Requirements</li>
              <li>Establish Success Metrics and Key Performance Indicators (KPIs)</li>
              <li>Model Selection, Development, Deployment, and Integration</li>
              <li>Incorporate AI/ML into Business Operations</li>
              <li>Performance and Security Optimization</li>

              </ul>
           </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Data Science Platform Deployment Initiative</h5>
              <ul class="card-text">
             <li>Strategic Planning and Roadmapping</li>
             <li>Data Integration from Diverse Sources</li>
             <li>Data Cleansing and Exploration</li>
              <li>Data Visualization</li>
              <li>Development of Machine Learning Models  </li>
              <li>Comprehensive Model Management</li>
              <li>Deployment and Ongoing Support</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text  ">
            <div class="card-body cloud-card-body-colors1"  >
              <h5 class="card-title">Data Science As A Service</h5><br/>
              <ul class="card-text">
              <li>Comprehend Business Challenges and Domain Context</li>
              <li>Analyze Processes, Technology Landscape, and Pain Points to Define a Strategic Roadmap</li>
              <li>Identify Use Cases, Perform Data Preparation, Mining, Conduct Exploratory Analytics, and Develop Models</li>
              <li>Drive Continuous Improvement Initiatives</li>

               </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Data Science Vigilance Program </h5>
              <ul class="card-text">
              <li>Provision and Manage Infrastructure for AI/ML Solutions</li>
              <li>Refine and Optimize Models, Achieve KPIs, Identify Issues, and Track Changes</li>
              <li>Educate on Big Data Technologies for Informed Adoption</li>
              <li>Ensure Data Quality and Maintain Data Catalog</li>
              <li>Provide Support and Maintenance Services</li>
              </ul>
           </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div></div> */}

          <div class="container">
            <div class="row" style={{ marginTop: "0px", marginBottom: "30px" }}>
              <div class="bi-service">
                <h1>Our Technology Expertises</h1>
              </div>

              <TechnologyList />
            </div>
          </div>
        </div>
      </div>

      {/* <ContactForm/> */}
      <Footers />
    </div>
  );
};

export default DataScience;
