// import React from 'react';
// import { Link } from 'react-router-dom';

// const AboutSSS = () => {
//   return (
//       <div className="container " style={{  borderRadius: "2%" }}>

//       <div className="bi-service">

//            <h1 className='text-center' style={{  fontSize: "40px" }}>
//             <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif" style={{width:"8%"}} />  ABOUT SSS
//         </h1>
//     {/* <h1 style={{fontSize:"40px"}}>ABOUT SSS</h1> */}
// </div>
//       <div className="row">
//         <div className="col-md-6">

//           <div className="wpb_text_column wpb_content_element">
//             <div className="wpb_wrapper">

//               <p style={{
//                 fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",fontSize:"18px",
//               textAlign: "justify",wordSpacing:"-2px"
//               }}>
//        SSS was founded in the year 2009 by <strong><Link to='Leadership/'>KALYANI RANGARAJAN</Link> </strong>and <strong><Link to='/Leadership'>RANGARAJAN KANDADAI</Link></strong> with a simple thought of delivering exceptional value to global customers. From the start,the vision was clear to empower businesses with world-class software solutions. They did this by tapping into local talent in their home state of Andhra Pradesh by building a platform for budding software developers to grow and nurture their careers. At Company SSS,we not only provide your business with exceptional enterprise-grade applications but go above and beyond. With 1000+ successful projects and a process-driven mindset, we’ve got your back. Our repeat clientele speaks volumes for our values and ethics in all our dealings–be it software development across web, mobile, analytics, cloud, or through our engineering.We believe in doing the best for our clients because WE CARE.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="col-md-6" style={{paddingTop:"1%"}}>
//           <div className="wpb_single_image wpb_content_element text-center">
//             <div className="wpb_wrapper">
//               <img
//                 width="720"
//                 height="1000"
//                 alt="leadership-group"
//                 title="leadership-group+Team"
//                 src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/Employees+All.png"
//                 className="vc_single_image-img img-fluid lazyloaded"
//                 decoding="async"
//               />
//             </div>
//           </div>
//               </div>

//           </div>

//        <div className="text-center" style={{marginTop:"10px"}}>
//       <Link
//         className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
//         target="_self"
//         to="/about-sss"
//       >
//         <span className="fusion-button-text">READ MORE</span>
//         <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
//       </Link>
//     </div>
//     </div>
//   );
// };

// export default AboutSSS;


import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './YourComponent.css';

const AboutSSS = () => {
  const images = [
  'Employees+All.png',
    'clients.jpg',
    
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,  // Add this line for automatic sliding
    autoplaySpeed: 3000,  // Set the speed for automatic sliding (in milliseconds)
  };

  return (
      <div className="container " style={{  borderRadius: "2%" }}>

      <div className="bi-service">

           <h2 className='text-center' style={{  fontSize: "38px" }}>
              Innovative Data Solutions for Tomorrow's Challenges: Unleash Your Competitive Edge
        </h2>
    {/* <h1 style={{fontSize:"40px"}}>ABOUT SSS</h1> */}
</div>
   <div className="bi-services">

<h2 className='text-center' style={{  fontSize: "24px" }}>
Future-proof your business with cutting-edge data solutions, gaining a competitive edge by anticipating and overcoming tomorrow's challenges with strategic innovation</h2>
{/* <h1 style={{fontSize:"40px"}}>ABOUT SSS</h1> */}
</div>

      <div className="row">
        <div className="col-md-6">

          <div className="wpb_text_column wpb_content_element">
            <div className="wpb_wrapper">

              <p style={{
                fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",fontSize:"18px",
              textAlign: "justify",wordSpacing:"-2px"
              }}>
       SSS was founded in the year 2009 by <strong><Link to='Leadership/'>KALYANI RANGARAJAN</Link> </strong>and <strong><Link to='/Leadership'>RANGARAJAN KANDADAI</Link></strong> with a simple thought of delivering exceptional value to global customers. From the start,the vision was clear to empower businesses with world-class software solutions. They did this by tapping into local talent in their home state of Andhra Pradesh by building a platform for budding software developers to grow and nurture their careers. At Company SSS,we not only provide your business with exceptional enterprise-grade applications but go above and beyond. With 1000+ successful projects and a process-driven mindset, we’ve got your back. Our repeat clientele speaks volumes for our values and ethics in all our dealings–be it software development across web, mobile, analytics, cloud, or through our engineering.We believe in doing the best for our clients because WE CARE.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6" style={{paddingTop:"1%"}}>
          {/* <div className="wpb_single_image wpb_content_element text-center">
            <div className="wpb_wrapper">
              <img
                width="720"
                height="1000"
                alt="leadership-group"
                title="leadership-group+Team"
                src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/Employees+All.png"
                className="vc_single_image-img img-fluid lazyloaded"
                decoding="async"
              />
            </div>
          </div> */}
          {/* <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={`https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/${image}`}
                alt={`image-${index + 1}`}
                className="vc_single_image-img img-fluid lazyloaded"
                decoding="async"
              />
            </div>
          ))}
        </Slider> */}
        <Slider {...sliderSettings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={`https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/${image}`}
                  alt={`image-${index + 1}`}
                  className="vc_single_image-img img-fluid lazyloaded"
                  decoding="async"  style={{width:"100%", height:"320px"}}
                />
              </div>
            ))}
          </Slider>
              </div>

          </div>

       <div className="text-center" style={{marginTop:"10px"}}>
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/about-sss"
      >
        <span className="fusion-button-text">READ MORE</span>
        <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
      </Link>
    </div>
    </div>
  );
};

export default AboutSSS;
