import React from "react";
import "./Footer.css";
import Footer from "./Footer";
import Footers from "./Footers";
const PrivacyPolicy = () => {
  return (
    <div pb-2 pt-2 style={{ backgroundColor: "white" }}>
      <div>
        <div class="container2">
          <img
            src="https://sssbiimages.s3.ap-south-1.amazonaws.com/footer/privacy-policy.jpg"
            alt="Snow"
            style={{
              height: "550px",
              width: "100%",
              transform: "skew-X(5deg)",
            }}
          />
          <div class="text-center">
            {" "}
            <img
              src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif"
              style={{ width: "10%" }}
            />{" "}
          </div>
        </div>

        <div class="container" style={{ backgroundColor: "#c1cce4" }}>
          <div class="row">
            <div class="mg-management">
              <h2>PRIVACY POLICY</h2>
            </div>
            <div class="privacy-p">
              <p>
                sssbi.com respects your privacy policy and supports the
                protection of consumer privacy on the Internet and the
                principles of disclosure through fair information practices.
                sssbi.com reserves the right to change this policy by providing
                notification of material change on website. sssbi.com utilizes
                safe, secure technology to protect your personally identifiable
                information. You may contact us with any questions regarding
                this policy at mailto:info@sssbi.com. The following discloses
                our information gathering and dissemination practices for this
                website:
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>USER INFORMATION DISCLOSURE</h2>
            </div>
            <div class="privacy-p">
              <p>
                We are the sole owners and responsible for the information
                collected on this site. We may collect/track personal
                information such as name, email address, phone, preferences home
                server domain names and interests etc. The Information collected
                is for authentication purposes and will be stored automatically
                in log files. The Contact Information will be for both service
                related and Integral component of application. The Data will be
                available as long as you are a user and make that data available
                to you upon the termination of your relationship with sssbi.com
                The Personal information provided by you will be used for
                providing attribution to comments you post for the improvement
                or to customize the content and layout of the sssbi.com website
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>COOKIES AND WEB BEACONS INFORMATION</h2>
            </div>
            <div class="privacy-p">
              <p>
                sssbi.com uses cookies to assist in delivering the service and
                to provide a personalized user experience. Cookies are small
                portion text files stored for record-keeping purposes. Session
                ID cookies provides easy access to files and are also used to
                identify unique visitors to our sites. If you choose to not
                accept cookies, you may be limited to use only some areas of our
                site only.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>INDEMNIFICATION</h2>
            </div>
            <div class="privacy-p">
              <p>
                You agree to defend, indemnify, and hold harmless sssbi.com its
                affiliates and subsidiaries, and all of their respective
                directors, officers, employees, representatives, proprietors,
                partners, shareholders, servants, principals, agents,
                predecessors, successors, assigns, and attorneys from and
                against any and all claims, proceedings, damages, injuries,
                liabilities, losses, costs, and expenses (including attorneys
                fees and litigation expenses) relating to or arising from your
                use of the Service and any breach by you of these Terms and
                Conditions. Third Party Services Information This website may
                contain links to third party websites and sssbi.com services may
                provide access to third party websites and services. We are not
                responsible for the privacy practices of these third parties. In
                order to understand the third party privacy, information
                collection, use practices please visit and review their privacy
                policies and practices once.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>LOG FILE INFORMATION</h2>
            </div>
            <div class="privacy-p">
              <p>
                Log file information is automatically maintained and reported by
                your browser each time you access a web page. It includes web
                request, IP address, referring pages ,domain names, landing
                pages, pages viewed and other information will be recorded and
                web browser will send the information as and when you visit any
                website.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>SECURITY & CONFIDENTIALITY</h2>
            </div>
            <div class="privacy-p">
              <p>
                sssbi.com uses reasonable physical, electronic & procedural
                safeguard security measures to protect the loss, misuse, and
                alteration of the information under our control. However, we
                cannot give guarantee to the protection of information and
                preservation of your privacy against any interception /
                misappropriation / misuse / alteration or that your information
                may be not be disclosed or accessed by accidental circumstances
                or by the unauthorized acts of others.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>PROMPT PERFORMANCE AND FEEDBACK</h2>
            </div>
            <div class="privacy-p">
              <p>
                sssbi.com may not always catch an unintended privacy issue,
                despite our efforts to do so. We will use reasonable efforts to
                comply with this Privacy Policy and will take prompt corrective
                action for any failure to comply with it. Accordingly, sssbi.com
                welcomes your feedback regarding any privacy concerns that you
                may have.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>BUSINESS TRANSACTIONS</h2>
            </div>
            <div class="privacy-p">
              <p>
                As we continue to develop our business, we might become involved
                in financing transactions or business transactions involving the
                sale or license of our assets or business units. In either case,
                Subscriber Information often is disclosed on a confidential
                basis or is one of the transferred business assets but remains
                subject to the promises made in any pre-existing Privacy Policy
                (unless, of course, the Subscriber consents otherwise). Also, in
                the event the Company is merged with another company or
                acquired, or substantially all of its assets are acquired,
                Subscriber Information will be one of the transferred assets.`
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>RIGHT TO CONTACT USERS</h2>
            </div>
            <div class="privacy-p">
              <p>
                sssbi.com reserves the right to contact service visitors
                regarding sales and other informational requests made through
                its web service.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="mg-management">
              <h2>POLICY CHANGES</h2>
            </div>
            <div class="privacy-p pb-4">
              <p>
                sssbi.com reserves the right to change this policy should we
                deem it advisable to do so. Any significant changes which we
                made in the site affects the use or disclosure of your personal
                information, we will make reasonable efforts to notify you those
                changes through mail. If you believe that sssbi.com has
                collected incorrect information or if you have any questions
                concerning our privacy policy, feel free to contact us
                mailto:info@sssbi.cominfo.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <Footers />
    </div>
  );
};

export default PrivacyPolicy;
