import React from 'react';
import './Counters.css';

const Counters = () => {
  return (
      <div>
          
<section className="counter-section" id="newbox" style={{padding:"10%"}}>
      <div className="pattern-layer" style={{backgroundImage: "url(https://sssbiimages.s3.ap-south-1.amazonaws.com/counter/pattern-12.webp)"}}></div>
      <div className="pattern-layer-two" style={{backgroundImage: "url(https://sssbiimages.s3.ap-south-1.amazonaws.com/counter/pattern-13.webp)"}}></div>
      {/* <div class="plus-icon" style={{backgroundImage: "url(https://sssindia.com/assets/img/background/icon-5.webp)"}}></div> */}
      <div className="container">
         <div className="auto-container">
            <div className="row clearfix align-items-center text-center" style={{marginLeft:"-80px"}}>
               <div className="counter-column col-lg-9 col-md-12 col-sm-12">
                  <div className="row clearfix align-items-center level">
                     <div className="column counter-column col-lg-3 col-md-3 col-sm-12  m-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <div className="inner">
                           <div className="content">
                              <h4 className="counter-title">Happy <br/>clients</h4>
                              <div id="counter-box">
                                 <div className="count-outer count-box alternate counted  d-flex justify-content-center">
                                    <div className="count-text counter">
                                       <p className="title js-count-up" id="hc-num"></p>
                                    </div>
                                    <span>12+</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="column counter-column col-lg-3 col-md-3 col-sm-12  m-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="inner">
                           <div className="content">
                                              <h4 className="counter-title">Project<br/> Delivered</h4>
                              <div id="counter-box">
                                 <div className="count-outer count-box alternate counted  d-flex justify-content-center">
                                    <div className="count-text counter">
                                       <p className="title js-count-up" id="pd-num"></p>
                                    </div>
                                    <span>1K+</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="column counter-column col-lg-3 col-md-3 col-sm-12 m-0  aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                        <div className="inner">
                           <div className="content">
                              <h4 className="counter-title">Technologies Mastered </h4>
                              <div id="counter-box">
                                 <div className="count-outer count-box alternate counted  d-flex justify-content-center">
                                    <div className="count-text counter">
                                       <p className="title js-count-up" id="team-num"></p>
                                    </div>
                                    <span>50+</span>
                                 </div>
                              </div>
                           </div>
                        </div>

                        
                                  </div>
                                  <div className="column counter-column col-lg-3 col-md-3 col-sm-12 m-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="2500">
                        <div className="inner">
                           <div className="content">
                              <h4 className="counter-title">Our Team Members</h4>
                              <div id="counter-box">
                                 <div className="count-outer count-box alternate counted  d-flex justify-content-center">
                                    <div className="count-text counter">
                                       <p className="title js-count-up" id="team-num"></p>
                                    </div>
                                    <span>100+</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="experiance-column col-lg-2 col-md-12 col-sm-12 justify-content-center">
               
                 <h4 className="counter-title" style={{fontSize:"22px",fontWeight:"700",color:"#1f3b7f",justifyContent:"center"}}>Years of Experience</h4> 
                  <div className="inner-column">
                     <div className="year">
                        <div id="counter-box">
                           <div className="d-flex justify-content-center">
                              <div className="count-text counter">
                                 <p className="title js-count-up" id="ex-num"></p>
                              </div>
                              {/* <h4 className="counter-title">Years of Experience</h4>  */}
                              <span> 14+ </span>
                             
                           </div>
                        </div>
                        {/* <div className="title">years of experience</div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
   </div></section>


    </div>
  )
}

export default Counters