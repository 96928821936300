import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./Gallery.css";

const images = [
  [
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Sankranti.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Sankranti+1.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/DIWALI+(1).jpg",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Indepedence+day.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Birthday+Celebrations+2.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Diwali.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Diwali+2.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Dasara+Celebrations+copy.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Birthday+celebrations.png",
  ],
  [
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/tech_talk-fun_friday/Tech+talk+1.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/tech_talk-fun_friday/Tech+talk+2.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/tech_talk-fun_friday/Tech+talk.png",
    //  "images/inspirations/insp3.jpg",
  ],
  [
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/SSS-trips/Team+outing.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/SSS-trips/Dasara+Celebrations+2.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/SSS-trips/Team+lunch.png",
    // "images/trip/trip4.jpg",
  ],
  [
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/appraisal-speeches/Appraisals+3.png",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/appraisal-speeches/Appraisals.png",
    // "images/apprasails/app3.jpg",
    // "images/apprasails/app4.jpg",
  ],
  [
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/Infrasctructure/Front+office.jpeg",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/Infrasctructure/Front+office+2.jpg",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/Infrasctructure/mam+cabin.JPEG",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/Infrasctructure/dir+cabin.JPEG",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/Infrasctructure/con1.jpg",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/Infrasctructure/bay.JPEG",
  ],
  [
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/fun_friday/b1.jpg",
    "https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/fun_friday/b2.jpg",
  ],
];

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryIndex: 0,
      photoIndex: 0,
      isOpen: false,
    };
  }

  handleImageClick = (categoryIndex, photoIndex) => {
    this.setState({
      categoryIndex,
      photoIndex,
      isOpen: true,
    });
  };

  render() {
    const { categoryIndex, photoIndex, isOpen } = this.state;
    const categoryImages = images[categoryIndex];

    return (
      <div
        className="container-fluid serviceDisplay"
        style={{ backgroundColor: "rgb(241, 234, 234)", marginTop: "15vh" }}
      >
        <div>
          <img
            src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/Gallery+slider.png"
            style={{ width: "100%" }}
          />
        </div>
        <div class="container-fluid">
          <div class="row"></div>
          <div className="row"></div>
        </div>

        <section
          className="page-section pb-100"
          style={{
            background: "#fffcec",
            backgroundColor: "rgb(241, 234, 234)",
          }}
        >
          <div className="line-container project-brief fw300 black-text">
            <div className="container">
              <div className="row life-inside-column">
                {/* <h2 className="text-center4" style={{ color: "#0d6efd" }}>
                  Life Inside SSS
                </h2> */}
                <div className="bi-service">
                  <h1>Life Inside SSS</h1>
                </div>

                <p
                  className="text-center life-inside-text"
                  style={{ fontSize: "22px" }}
                >
                  ‘Family Spirit’ is the heart of everything that we do at SSS.
                  Be it fun, learning, or being together through thick and thin!
                </p>
              </div>
            </div>

            <div className="row pt-50">
              <div className="col-lg-4">
                <div className="home-gal">
                  <p
                    className="zoom-in"
                    onClick={() => this.handleImageClick(0, 0)}
                  >
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Sankranti.png"
                      className="circle-image"
                      alt=""
                      style={{ height: "200px", borderRadius: "3%" }}
                    />
                  </p>
                  <h5>EVENTS & CELEBRATIONS</h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="home-gal">
                  <p
                    className="zoom-in"
                    onClick={() => this.handleImageClick(1, 0)}
                  >
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/tech_talk-fun_friday/Tech+talk+1.png"
                      className="circle-image"
                      alt=""
                      style={{ height: "200px", borderRadius: "3%" }}
                    />
                  </p>
                  <h5 style={{ marginLeft: "40px" }}>
                    TECH TALKS/INSPIRATIONS
                  </h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="home-gal">
                  <p
                    className="zoom-in"
                    onClick={() => this.handleImageClick(2, 0)}
                  >
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/SSS-trips/Team+outing.png"
                      className="circle-image"
                      alt=""
                      style={{ height: "200px", borderRadius: "3%" }}
                    />
                  </p>
                  <h5>TRIPS</h5>
                </div>
              </div>
              {/* <div className="col-lg-3" >
            <div className="home-gal" >
              <p  className="zoom-in"  onClick={() => this.handleImageClick(2,0)}>
                <img
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/SSS-trips/Team+outing.png" className="circle-image"
                  alt="" style={{height:"200px",borderRadius:"3%"}}
                />
              </p>
              <h5>INSPIRATIONAL SPEECH</h5>
            </div>
          </div> */}
            </div>
            <div className="row pt-100">
              <div className="col-lg-4">
                <div className="home-gal">
                  <p
                    className="zoom-in"
                    onClick={() => this.handleImageClick(3, 0)}
                  >
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/appraisal-speeches/Appraisals+3.png"
                      className="circle-image"
                      alt=""
                      style={{ height: "200px", borderRadius: "3%" }}
                    />
                  </p>
                  <h5>APPRAISALS</h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="home-gal">
                  <p
                    className="zoom-in"
                    onClick={() => this.handleImageClick(4, 0)}
                  >
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/Infrasctructure/Front+office.jpeg"
                      className="circle-image"
                      alt=""
                      style={{
                        height: "200px",
                        borderRadius: "3%",
                        width: "350px",
                      }}
                    />
                  </p>
                  <h5>INFRASTRUCTURE</h5>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="home-gal">
                  <p
                    className="zoom-in"
                    onClick={() => this.handleImageClick(5, 0)}
                  >
                    <img
                      src="https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/fun_friday/b1.jpg"
                      className="circle-image"
                      alt=""
                      style={{ height: "200px", borderRadius: "3%" }}
                    />
                  </p>
                  <h5>FUN FRIDAY'S</h5>
                </div>
              </div>
              {/* <div className="col-lg-3">
            <div className="home-gal" >
              <p  className="zoom-in" onClick={() => this.handleImageClick(5,0)}>
                <img
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/gallery/fun_friday/b1.jpg" className="circle-image"
                  alt="" style={{height:"200px",borderRadius:"3%"}}
                />
              </p>
              <h5>FUN FRIDAY'S</h5>
            </div>
          </div> */}
            </div>
            <div className="row"></div>
          </div>
        </section>

        {isOpen && (
          <Lightbox
            mainSrc={categoryImages[photoIndex]}
            nextSrc={categoryImages[(photoIndex + 1) % categoryImages.length]}
            prevSrc={
              categoryImages[
                (photoIndex + categoryImages.length - 1) % categoryImages.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + categoryImages.length - 1) %
                  categoryImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % categoryImages.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default Gallery;
