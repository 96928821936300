// import React from 'react';
// import './Home.css';
// import Footer from '../components/Footer';
// import ContactUss from './ContactUss';
// // import { Carousel } from 'react-bootstrap';
// import Carousel from 'react-bootstrap/Carousel';
// import TechnologyList from './TechnologyList';
// import CareerSection from './CareerSection';
// import Clients from './Clients';
// import CounterSection from './CounterSection';
// import AboutHome from './AboutHome';
// import Services from './Services';
// import Why from './Why';
// import Whyy from './Whyy';
// import Counters from './Counters';
// import Testimonials from './Testimonials';
// import { FALSE } from 'sass';
// import Flags from './Flags';
// import HomeVideo from './HomeVideo';
// const backgroundImage = 'https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/careers.jpg';
// const Home= () => {
//     return (
//       <div  className="videoDisplay" style={{marginTop:'20vh'}}>
//     {/* <div id="carouselVideoExample" className="carousel slide carousel-fade" data-ride="carousel">

//             </div>

//           <div className="carousel-inner ">

//         <div className="">
//           <video className="img-fluid"  autoPlay loop muted>
//             <source src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/Home.mp4" type="video/mp4" />
//           </video>

//         </div>

//         </div> */}

//         <HomeVideo/>

//        <AboutHome/>

//         <Counters/>

//           <Services/>

//       <CareerSection/>

//           <Whyy/>

//        <Flags/>

//         <div class="container">
//     <div class="row3" style={{marginTop:"0px",marginBottom:"30px",justifyContent:"flex-start"}}>
//     <div class="bi-service">
//     <h3>Our Technology Expertises</h3>
// </div>

//        <TechnologyList/>

//     </div>
//         </div>

//         <Clients/>

// <Testimonials/>

//         <Footer />

//             </div>
//   );
// };

// export default Home;

import React from "react";
import "./Home.css";
import Footer from "../components/Footer";
import ContactUss from "./ContactUss";
// import { Carousel } from 'react-bootstrap';
import Carousel from "react-bootstrap/Carousel";
import TechnologyList from "./TechnologyList";
import CareerSection from "./CareerSection";
import Clients from "./Clients";
import CounterSection from "./CounterSection";
import AboutHome from "./AboutHome";
import Services from "./Services";
import Why from "./Why";
import Whyy from "./Whyy";
import Counters from "./Counters";
import Testimonials from "./Testimonials";
import { FALSE } from "sass";
import Flags from "./Flags";
import HomeVideo from "./HomeVideo";
import Test from "../components/Test";
import Sliders from "./Sliders";
import Footers from "./Footers";
const backgroundImage =
  "https://sssbiimages.s3.ap-south-1.amazonaws.com/Employee+Images/careers.jpg";
const Home = () => {
  return (
    <div className="videoDisplay" style={{ marginTop: "20vh" }}>
      <HomeVideo />

      <AboutHome />

      <Counters />

      <Services />

      <CareerSection />

      <Whyy />

      <Flags />

      <div class="container">
        <div
          class="row3"
          style={{
            marginTop: "0px",
            marginBottom: "30px",
            justifyContent: "flex-start",
          }}
        >
          <div class="bi-service">
            <h3>Our Technology Expertises</h3>
          </div>

          <TechnologyList />
        </div>
      </div>

      <Clients />

      <Testimonials />

      {/* <Test/> */}

      {/* <Sliders/> */}

      {/* <Footer /> */}
      <Footers />
    </div>
  );
};

export default Home;
