import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function CloudMigrationCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 780,
          },
        ]}
        speed={400}
        easing="linear"
      >

        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size"  >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Advisory-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Cloud Migration ​Advisory Services​</h5>
      <ul class="card-text " >
        <li>Assessment and Planning​</li>
        <li>Architecture and Design​</li>
        <li>Security and Compliance</li>
        <li>Migration Strategy​</li>
        <li>Performance Optimization</li>
        <li>Post-Migration Support</li>
        <li>Immediate Issue Resolution</li>
            <li>Accessibility and Collaboration</li>
            <li>Risk Analysis and Mitigation</li>
       </ul> </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size" >
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-BI.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black">Cloud Migration for BI, Data Science solutions​</h5>
      <ul class="card-text" >
      <li> Reduced Maintenance Burden</li>
      <li>Rapid Prototyping and Experimentation​</li>
      <li>Automation and Orchestration</li>
      <li> Advanced Analytics Tools​</li>
      <li>Seamless workload migration Cloud governance and security</li>
      <li>Scalability and Performance</li>
      {/* <li>Accessibility and Collaboration</li> */}

       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Cloud Migration ​Managed Services​</h5>
      <ul class="card-text" >
      <li>Aids DevOps practices​​</li>
      <li>Manages AWS, Azure, or GCP cloud migration platforms​</li>
      <li>Infrastructure Managemen​</li>
      {/* <li>Utilizes analytics tools</li> */}
      <li>Security and Compliance, flexible infrastructure for data analytics​</li>
      <li>Accelerates development cycles, time-to-market for new features</li>
       </ul>
    </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-BI.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Cloud Migration for BI, Data Science solutions​</h5>
      <ul class="card-text" >
      <li> Reduced Maintenance Burden</li>
      <li>Rapid Prototyping and Experimentation​</li>
      <li>Automation and Orchestration</li>
      <li> Advanced Analytics Tools​</li>
      <li>Seamless workload migration Cloud governance and security</li>
      <li>Scalability and Performance</li>


       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Cloud Migration ​Managed Services​</h5>
      <ul class="card-text" >
      <li>Aids DevOps practices​​</li>
      <li>Manages AWS, Azure, or GCP cloud migration platforms​</li>
      <li>Infrastructure Managemen​</li>
      {/* <li>Utilizes analytics tools</li> */}
      <li>Security and Compliance, flexible infrastructure for data analytics​</li>
      <li>Accelerates development cycles, time-to-market for new features</li>
       </ul>
    </div>
  </div>

        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbiimages.s3.ap-south-1.amazonaws.com/services/cloud_migration/Cloud-Migration.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Cloud ​Migration <br/>Services</h5>
      <ul class="card-text">
      <li>Deep dive into main frame apps, batches, datasets​</li>
      <li>Migrate to Big Data, decommission mainframe</li>
      <li>Archive legacy data on Hadoop, Elasticsearch</li>
      <li>Re-platform ETL processes with Spark</li>
      <li>Offload EDW to Big Data, Cloud migration​</li>
      <li>Data Migration Strategy</li>
       </ul>
    </div>
  </div>
        </div>

      </ReactSimplyCarousel>
    </div>
  );
}

export default CloudMigrationCarousel;
