import React from "react";
import "font-awesome/css/font-awesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./LeadershipTeam.css";
import Footer from "../components/Footer";
import ContactUss from "../components/ContactUss";
import { Link } from "react-router-dom";
import Footers from "./Footers";
const LeadershipBanner = () => {
  const bannerStyle = {
    backgroundImage:
      'url("https://sssbiimages.s3.ap-south-1.amazonaws.com/Directors/partnership.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
  };

  return (
    <div>
      <div className="container-fluid" style={bannerStyle}>
        <div
          className="row align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="col-12 col-md-6">
            {/* <div className="text-center">
            <h1 className="h1 text-white" >Our Leadership Team</h1>
          </div> */}
            <div className="bi-service">
              <h1>Our Leadership Team</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            {/* <div className="fusion-text fusion-text-2 fusion-text-no-margin innerGradientTitle"
               style={{ fontSize: "35px", lineHeight: "40px", marginBottom: "0px", marginLeft: "4%", fontFamily: "AmsiPro-Regular", fontStyle: "normal", fontWeight: 400 }}>
            <h2 className="text-center mb-4"style={{color:"black"}}>Our Leadership Team</h2>
          </div> */}

            <div class="bi-service">
              <h1 className="text-center" style={{ fontSize: "40px" }}>
                <img
                  src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif"
                  style={{ width: "10%" }}
                />{" "}
                Our Leadership Team
              </h1>
              {/* <h1 style={{fontSize:"40px"}}>ABOUT SSS</h1> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-3">
            <div className="fusion-column-wrapper text-center mb-5 custom-border">
              <div className="fusion-image-element">
                <div class="image_wrapper">
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Directors/Kalyani+Mam.png"
                    alt="KALYANI RANGARAJAN"
                    className="img-fluid"
                  />
                  <div class="overlay overlay_4">
                    <h6
                      style={{ fontFamily: "Helvetica Neue", fontSize: "20px" }}
                    >
                      Over 20 years of commendable record of building, leading,
                      Supporting world class business intelligence and Data
                      Warehousing solutions. Kalyani has established SSS in 2009
                      with a motive to provide best-in-class, cost effective IT
                      Services and Enterprise Solutions. She has an experience
                      as a corporate consultant and extensively worked with many
                      leading companies on Data Warehousing, ETL and Reporting
                      Tools.
                    </h6>
                  </div>
                </div>
              </div>

              <div className="container1">
                <div className="fusion-text fusion-text-3 fusion-text-no-margin">
                  <h3 className="mb-3">
                    <strong className="font">KALYANI RANGARAJAN</strong>
                  </h3>
                </div>
                <div className="fusion-text fusion-text-4 fusion-text-no-margin">
                  <h4
                    className="mb-0"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Helvetica Neue",
                      fontSize: "20px",
                    }}
                  >
                    Group CEO
                  </h4>
                </div>

                {/* <Link to="default.asp">
                   <img src="https://cdn-icons-png.flaticon.com/512/3536/3536394.png" alt="linkedin tutorial"
                    style={{ width: "13%", height: "13%" }}

  />
                </Link> */}
                <Link to="https://www.linkedin.com/in/mjf-ln-dr-kalyani-rangarajan-cbip-49160017/">
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/018/930/587/non_2x/linkedin-logo-linkedin-icon-transparent-free-png.png"
                    alt="HTML tutorial"
                    style={{ width: "20%", height: "20%" }}
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3">
            <div className="fusion-column-wrapper text-center mb-5 custom-border">
              <div className="fusion-image-element">
                <div class="image_wrapper">
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Directors/MD+Sir.png"
                    alt="Rangarajan"
                    className="img-fluid rounded-circle"
                  />
                  <div class="overlay overlay_4">
                    <h6
                      style={{ fontFamily: "Helvetica Neue", fontSize: "20px" }}
                    >
                      Over 30 years of enormous experience in Technical &amp;
                      Functional Roles, in the fields of Financial,
                      Administration, and Management. He is a visionary with an
                      optimistic mind and a courteous heart. He is a great
                      leader with strong interpersonal skills to motivate and
                      influence people with his exemplary outlook.
                    </h6>
                  </div>
                </div>
              </div>
              <div className="container1">
                <div className="fusion-text fusion-text-3 fusion-text-no-margin">
                  <h3 className="mb-3">
                    <strong className="font">RANGARAJAN KANDADAI</strong>
                  </h3>
                </div>
                <div className="fusion-text fusion-text-4 fusion-text-no-margin">
                  <h4
                    className="mb-0"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Helvetica Neue",
                      fontSize: "20px",
                    }}
                  >
                    Managing Director
                  </h4>
                </div>
                {/* 
                    <Link to="default.asp">
                   <img src="https://cdn-icons-png.flaticon.com/512/3536/3536394.png" alt="HTML tutorial"
                    style={{ width: "13%", height: "13%" }}

  />
                </Link> */}

                <Link to="https://www.linkedin.com/in/rangarajan-kandadai-7194721b/">
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/018/930/587/non_2x/linkedin-logo-linkedin-icon-transparent-free-png.png"
                    alt="HTML tutorial"
                    style={{ width: "20%", height: "20%" }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="fusion-column-wrapper text-center mb-5 custom-border">
              <div className="fusion-image-element">
                <div class="image_wrapper">
                  <img
                    src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Directors/ranga+sir.png"
                    alt="ranga sir"
                    className="img-fluid rounded-circle"
                  />
                  <div class="overlay overlay_4">
                    <h6
                      style={{ fontFamily: "Helvetica Neue", fontSize: "18px" }}
                    >
                      Has over 13 years of splendid adroitness in Oracle BI,
                      with end-to-end Enterprise Solutions and ERP
                      implementation. He is proficient in OBIEE, ETL
                      Informatica, SQL, Cognos, ODI, BI Apps, Qlikview and
                      MYSQL. He has strong domain knowledge of Enterprise
                      Resource Planning and Supply Chain Management. He provides
                      clear direction and sets roadmaps for our projects. He has
                      a strong ability to influence and coach teams.
                    </h6>
                  </div>
                </div>
              </div>

              <div className="container1">
                <div className="fusion-text fusion-text-3 fusion-text-no-margin">
                  <h3 className="mb-3">
                    <strong className="font">RANGANATHAN TIRUMALAI</strong>
                  </h3>
                </div>
                <div className="fusion-text fusion-text-4 fusion-text-no-margin"></div>
                <h4
                  className="mb-0"
                  style={{
                    color: "white",
                    fontFamily: "Helvetica Neue",
                    fontSize: "20px",
                  }}
                >
                  Director
                </h4>

                {/* <Link to="default.asp">
                   <img src="https://cdn-icons-png.flaticon.com/512/3536/3536394.png" alt="HTML tutorial"
                    style={{ width: "13%", height: "13%" }}

  />
                </Link> */}
                <Link to="default.asp">
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/018/930/587/non_2x/linkedin-logo-linkedin-icon-transparent-free-png.png"
                    alt="HTML tutorial"
                    style={{ width: "20%", height: "20%" }}
                  />
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="col-12 col-md-3">

          <div className="fusion-column-wrapper text-center mb-5 custom-border">
                  <div className="fusion-image-element">
                    <div class="image_wrapper">
              <img
                src="https://sssbiimages.s3.ap-south-1.amazonaws.com/Directors/Krishna+sir.png"
                alt="Krishna Chaithanya"
                className="img-fluid rounded-circle"
                    />

                       <div class="overlay overlay_4">
        <h6 style={{fontFamily:"Helvetica Neue",fontSize:"20px"}}>Over 20 years in DnA Experienced in Cloud, Data Lake &amp; Data Science
Self-sustaining &amp; hybrid solutions implementation across many MNC's.
Veteran in wearing Multiple Hats in an organization from program Manager,
Architect to a hands-on developer; with Data Analytics instinct all the time.</h6>
                    </div>
                  </div>
                  </div>
                <div className='container1'>
            <div className="fusion-text fusion-text-3 fusion-text-no-margin">
              <h3 className="mb-3"><strong className="font">KRISHNA CHAITHANYA</strong></h3>
            </div>
            <div className="fusion-text fusion-text-4 fusion-text-no-margin">
              <h4 className="mb-0" style={{ color: '#ffffff',fontFamily:"Helvetica Neue",fontSize:"20px"}}>Director</h4>
            </div>








                            <Link to="https://www.linkedin.com/in/krishna-chaitanya-mucheli-613a7850/">
                  <img src="https://static.vecteezy.com/system/resources/previews/018/930/587/non_2x/linkedin-logo-linkedin-icon-transparent-free-png.png" alt="HTML tutorial"
                    style={{ width: "20%", height: "20%" }}

  />
                  </Link>
                  </div>
                  </div>

        </div> */}
        </div>
      </div>
      <ContactUss />
      <Footers />
    </div>
  );
};

export default LeadershipBanner;
